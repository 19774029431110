import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";

export default function Login() {
  const history = useHistory();

  const [email, setEmail] = useState("");

  useEffect(() => {
    if (!isEmpty(localStorage.getItem("token"))) {
      if (
        localStorage.getItem("role") === "gerente" ||
        localStorage.getItem("role") === "director"
      ) {
        history.push("/admin/actividades");
      }
      if(
        localStorage.getItem("role") === "admin" ||
        localStorage.getItem("role") === "proveedor"
      ){
        history.push("/admin/dashboard");
      }
    }
  }, []);

  const disabled = true;

  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundColor: "#8d8d8d",
            }}
            // style={{
            //   backgroundImage:
            //     "url(" + require("assets/img/register_bg_2.png") + ")",
            // }}
          ></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3 flex items-center justify-center">
                      <img
                        alt="..."
                        className="m-2"
                        width="100"
                        src={require("assets/img/logo-palmas.png")}
                      />
                      <img
                        alt="..."
                        className="m-2"
                        width="80"
                        src={require("assets/img/logo.png")}
                      />
                    </div>

                    <hr className="mt-6 border-b-1 border-gray-400" />
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Correo
                        </label>
                        <input
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="Email"
                        />
                      </div>

                      <div className="text-center mt-6">
                        <button
                          
                          className={`bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                          type="button"
                        >
                          Enviar
                        </button>
                        <button
                          onClick={() => history.push("/login")}
                          className={`bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                          type="button"
                        >
                          Volver
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
