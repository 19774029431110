import * as auth from './auth.api';
import * as users from './users.api';
import * as payments from './payments.api';
import * as materials from './materials.api';
import * as providers from './providers.api';

const api = {
	auth,
	users,
	payments,
	materials,
	providers,
};

export default api;
