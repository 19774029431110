import React from "react";
import { Map, HeatMap, GoogleApiWrapper } from "google-maps-react";
import repository from "../../repository";
import _ from "lodash";

const gradient = [
  "rgba(0, 255, 255, 0)",
  "rgba(0, 255, 255, 1)",
  "rgba(0, 191, 255, 1)",
  "rgba(0, 127, 255, 1)",
  "rgba(0, 63, 255, 1)",
  "rgba(0, 0, 255, 1)",
  "rgba(0, 0, 223, 1)",
  "rgba(0, 0, 191, 1)",
  "rgba(0, 0, 159, 1)",
  "rgba(0, 0, 127, 1)",
  "rgba(63, 0, 91, 1)",
  "rgba(127, 0, 63, 1)",
  "rgba(191, 0, 31, 1)",
  "rgba(255, 0, 0, 1)",
];

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
    };
  }

  async componentDidMount() {
    const data = await repository.activities.getAllFormWithActivity();
    const forms = data.docs.map((d) => {
      return { _id: d.id, ...d.data() };
    });

    let newLocations = _.filter(forms, (form) => {
      return (
        !_.isEmpty(form.position) &&
        form.proveedor.plant === (localStorage.plt === "tumaco" ? "TCO" : "ESC")
      );
    });
    
    this.setState({
      locations: newLocations.map((l) => {
        return { lat: l.position.latitude, lng: l.position.longitude };
      }),
    });
  }

  render() {
    const { locations } = this.state;

    return (
      <div className="map-container">
        {locations.length > 0 && (
          <Map
            google={this.props.google}
            className={"map"}
            zoom={this.props.zoom}
            initialCenter={ localStorage.plt == "tumaco" ? {lat: 2.037288056404862, lng: -78.8046523718343} : { lat:4.6900511742439015, lng: -72.93297009959586 }}
            onReady={this.handleMapReady}
          >
            <HeatMap
              gradient={gradient}
              positions={locations}
              opacity={1}
              radius={20}
            />
          </Map>
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCDyA5w45BLsQJbbmzFKnn6JLGS2dQ4DUk",
  libraries: ["visualization"],
})(MapContainer);
