import * as constants from '../constants/plant.constants';

const plantReducer = (
	state = {
		plant: 'tumaco'
	},
	action,
) => {
	switch (action.type) {
		case constants.SET_PLANT:
			localStorage.setItem('plt', action.payload);
			return { plant: action.payload };

		default:
			return state;
	}
};

export default plantReducer;
