import request from '../utils/request.util';

import { isArray } from 'lodash';
import xml2js from 'xml2js';

export async function getProviders(plant = 'TCO'){
	let res = null;

	if (localStorage.role === 'director' && localStorage.plant) {
		if(localStorage.getItem(`${localStorage.role}-${localStorage.plt}`)){
			const soap = await new Promise((resolve, reject) => { 
				const data = localStorage.getItem(`${localStorage.role}-${localStorage.plt}`);
				resolve(JSON.parse(data));
			});
			return { data: soap };
		}
		res = await request({
			host: 'https://api.palmares.co/',
			url: `soap/getInformacionProveedor`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'POST',
			body: {
				planta: localStorage.plant
			}
		});
	} else if (localStorage.role === 'gerente' && localStorage.plt) {
		if(localStorage.getItem(`${localStorage.role}-${localStorage.plt}`)){
			const soap = await new Promise((resolve, reject) => { 
				const data = localStorage.getItem(`${localStorage.role}-${localStorage.plt}`);
				resolve(JSON.parse(data));
			});
			return { data: soap };
		}
		res = await request({
			host: 'https://api.palmares.co/',
			url: `soap/getInformacionProveedor`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'POST',
			body: {
				planta: localStorage.plt === 'tumaco' ? 'TCO' : 'ESC'
			}
		});
	} else {
		if(localStorage.getItem(`${localStorage.role}-${localStorage.plt}`)){
			const soap = await new Promise((resolve, reject) => { 
				const data = localStorage.getItem(`${localStorage.role}-${localStorage.plt}`);
				resolve(JSON.parse(data));
			});
			return { data: soap };
		}
		res = await request({
			host: 'https://api.palmares.co/',
			url: 'soap/getInformacionProveedor',
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'POST',
			body: {
				planta: plant
			}
		});
	}

	const providers = [];

	if (res !== null) {
		const soap = await new Promise((resolve, reject) => {
			xml2js.parseString(res.data, {trim: true, explicitArray: true}, (err, result) => {
				if(err) {
					reject(err);
				}
	
				if (result['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['WsProduccionSDT.PROVEEDORResponse'][0]['Sdtproveedorfruto'][0]['SDTProveedorFrutoItem']) {
					result['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['WsProduccionSDT.PROVEEDORResponse'][0]['Sdtproveedorfruto'][0]['SDTProveedorFrutoItem'].map((item) => {
						providers.push({
							nit: isArray(item.NumeroIdentificacion) ? item.NumeroIdentificacion[0] : item.NumeroIdentificacion,
							id: isArray(item.NumeroIdentificacion) ? item.NumeroIdentificacion[0] : item.NumeroIdentificacion,
							representative_name:  `${isArray(item.RazonSocial) ? item.RazonSocial[0] : item.RazonSocial}`,
							provider_type:  isArray(item.Company) ? item.Company[0] : item.Company,
							phone:  isArray(item.Telefono) ? item.Telefono[0] : item.Telefono,
							email:  isArray(item.Email,) ?  item.Email[0] : item.Email,
							city:  isArray(item.Municipio,) ?  item.Municipio[0] : item.Municipio,
							vereda:  isArray(item.Vereda,) ?  item.Vereda[0] : item.Vereda,
							address:  `${isArray(item.Latitud) ? item.Latitud[0] : item.Latitud} ${isArray(item.Longitud) ? item.Longitud[0] : item.Longitud}`, 
							latitud:  isArray(item.Latitud) ? item.Latitud[0] : item.Latitud,
							longitud:  isArray(item.Longitud) ? item.Longitud[0] : item.Longitud ,
							sinc: isArray(item.Sincronizado) ? item.Sincronizado[0] : item.Sincronizado ,
							contac: isArray(item.Contacto) ? item.Contacto[0] : item.Contacto ,
							tipoIdentificacion: isArray(item.TipoIdentificacion) ? item.TipoIdentificacion[0] : item.TipoIdentificacion ,
							primerNombre: isArray(item.PrimerNombre) ? item.PrimerNombre[0] : item.PrimerNombre ,
							segundoNombre: isArray(item.SegundoNombre) ? item.SegundoNombre[0] : item.SegundoNombre ,
							primerApellido: isArray(item.PrimerApellido) ? item.PrimerApellido[0] : item.PrimerApellido ,
							segundoApellido: isArray(item.SegundoApellido) ? item.SegundoApellido[0] : item.SegundoApellido,
							plant: isArray(item.Company) ? item.Company[0] : item.Company,
							hectareas: isArray(item.Hectareas) ? item.Hectareas[0] : item.Hectareas,
						});
					})
				}
				localStorage.setItem(`${localStorage.role}-${localStorage.plt}`, JSON.stringify(providers));
				resolve(providers);
			});
		})

		return { data: soap };
	} else {
		return { data: [] };
	}
}

export async function getProviderByNit(plant = 'TCO', nit){

		const res = await request({
			host: 'https://api.palmares.co/',
			url: `soap/getInformacionProveedor`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'POST',
			body: {
				planta: plant,
				nitproveedor: nit
			}
		});
	

	const providers = [];

	if (res !== null) {
		const soap = await new Promise((resolve, reject) => {
			xml2js.parseString(res.data, {trim: true, explicitArray: true}, (err, result) => {
				if(err) {
					reject(err);
				}
	
				if (result['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['WsProduccionSDT.PROVEEDORResponse'][0]['Sdtproveedorfruto'][0]['SDTProveedorFrutoItem']) {
					result['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['WsProduccionSDT.PROVEEDORResponse'][0]['Sdtproveedorfruto'][0]['SDTProveedorFrutoItem'].map((item) => {
						providers.push({
							nit: isArray(item.NumeroIdentificacion) ? item.NumeroIdentificacion[0] : item.NumeroIdentificacion,
							id: isArray(item.NumeroIdentificacion) ? item.NumeroIdentificacion[0] : item.NumeroIdentificacion,
							representative_name:  `${isArray(item.RazonSocial) ? item.RazonSocial[0] : item.RazonSocial}`,
							provider_type:  isArray(item.Company) ? item.Company[0] : item.Company,
							phone:  isArray(item.Telefono) ? item.Telefono[0] : item.Telefono,
							email:  isArray(item.Email,) ?  item.Email[0] : item.Email,
							city:  isArray(item.Municipio,) ?  item.Municipio[0] : item.Municipio,
							vereda:  isArray(item.Vereda,) ?  item.Vereda[0] : item.Vereda,
							address:  `${isArray(item.Latitud) ? item.Latitud[0] : item.Latitud} ${isArray(item.Longitud) ? item.Longitud[0] : item.Longitud}`, 
							latitud:  isArray(item.Latitud) ? item.Latitud[0] : item.Latitud,
							longitud:  isArray(item.Longitud) ? item.Longitud[0] : item.Longitud ,
							sinc: isArray(item.Sincronizado) ? item.Sincronizado[0] : item.Sincronizado ,
							contac: isArray(item.Contacto) ? item.Contacto[0] : item.Contacto ,
							tipoIdentificacion: isArray(item.TipoIdentificacion) ? item.TipoIdentificacion[0] : item.TipoIdentificacion ,
							primerNombre: isArray(item.PrimerNombre) ? item.PrimerNombre[0] : item.PrimerNombre ,
							segundoNombre: isArray(item.SegundoNombre) ? item.SegundoNombre[0] : item.SegundoNombre ,
							primerApellido: isArray(item.PrimerApellido) ? item.PrimerApellido[0] : item.PrimerApellido ,
							segundoApellido: isArray(item.SegundoApellido) ? item.SegundoApellido[0] : item.SegundoApellido,
							plant: isArray(item.Company) ? item.Company[0] : item.Company,
						});
					})
				}
				resolve(providers);
			});
		})
		
		return { data: soap };
	} else {
		return { data: [] };
	}
}

export async function getById(id){
	return request({
		host: 'https://api.palmares.co/',
		url: `farmers/${id}`,
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'GET',
	});
}

export async function getTotal(){
	if (localStorage.role === 'director' && localStorage.plant) {
		return request({
			host: 'https://api.palmares.co/',
			url: `farmers/getTotalFarmerByPlanta/planta/${localStorage.plant}`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	} else if (localStorage.role === 'gerente' && localStorage.plt) {
		return request({
			host: 'https://api.palmares.co/',
			url: `farmers/getTotalFarmerByPlanta/planta/${localStorage.plt === 'tumaco' ? 'TCO' : 'ESC' }`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	} else {
		return request({
			host: 'https://api.palmares.co/',
			url: 'farmers/getTotalfarmer',
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	}

	
}

export async function getByUserId(userId){
	return request({
		host: 'https://api.palmares.co/',
		url: `farmers/getByUserId/${userId}`,
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'GET',
	});
}

export async function save(provider){
	return request({
		host: 'https://api.palmares.co/',
		url: 'farmers',
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'POST',
		body: {
      ...provider
		}
	});
}

export async function update(provider){
	return request({
		host: 'https://api.palmares.co/',
		url: `farmers/${provider.id}`,
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'PUT',
		body: {
     ...provider
		}
	});
}


export async function remove(id){
	return request({
		host: 'https://api.palmares.co/',
		url: `farmers/${id}`,
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'DELETE',
		body: {}
	});
}


export async function verifyProviderByNit(nit){
	return request({
		host: 'https://api.palmares.co/',
		url: `users/getProvedorByIdentificationCard/${nit}`,
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'GET',
	});
}