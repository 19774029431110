import request from '../utils/request.util';

export async function getUsers(){
	
	if (localStorage.role === 'gerente' && localStorage.plt) {
		return request({
			host: 'https://api.palmares.co/',
			url: `users/findUserByPlanta/${localStorage.plt === 'tumaco' ? 'TCO' : 'ESC'}`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	} else if (localStorage.role === 'director' && localStorage.plant) {
		return request({
			host: 'https://api.palmares.co/',
			url: `users/findUserByPlantaAndDirector/${localStorage.plant}/id/${localStorage.id}`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	} else {
		return request({
			host: 'https://api.palmares.co/',
			url: 'users',
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	}
}

export async function getVinculadosBuyPlanta(){
	
	if (localStorage.role === 'gerente' && localStorage.plt) {
		return request({
			host: 'https://api.palmares.co/',
			url: `users/getProveedoresRegistradosByPlata/${localStorage.plt === 'tumaco' ? 'TCO' : 'ESC'}`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	} else if (localStorage.role === 'director' && localStorage.plant) {
		return request({
			host: 'https://api.palmares.co/',
			url: `users/getProveedoresRegistradosByPlata/${localStorage.plant === 'TCO' ? 'TCO' : 'ESC'}`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	}
}

export async function getTotal(){
	return request({
		host: 'https://api.palmares.co/',
		url: 'users/getTotalUser',
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'GET',
	});
}

export async function getAdvisers(id){
	
	if (localStorage.role === 'gerente' && localStorage.plt) {
		return request({
			host: 'https://api.palmares.co/',
			url: `users/findAsesoresByPlanta/${localStorage.plt === 'tumaco' ? 'TCO' : 'ESC'}`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	} else {
		return request({
			host: 'https://api.palmares.co/',
			url: `users/findAsesoresByIdAndPlanta/${localStorage.plant}/id/${id}`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	}
}


export async function getById(id){
	return request({
		host: 'https://api.palmares.co/',
		url: `users/${id}`,
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'GET',
	});
}


export async function getByRole(role){
	if (localStorage.role === 'gerente' && localStorage.plt) {
		return request({
			host: 'https://api.palmares.co/',
			url: `users/role/${role}/planta/${localStorage.plt === 'tumaco' ? 'TCO' : 'ESC'}`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	} else {
		return request({
			host: 'https://api.palmares.co/',
			url: `users/role/${role}`,
			headers: {
				Authorization: `Bearer ${localStorage.token}`,
			},
			method: 'GET',
		});
	}
}

export async function save(email, password, names, surnames, id, type_id, role, managerId, plant){
	return request({
		host: 'https://api.palmares.co/',
		url: 'auth/sign-up',
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'POST',
		body: {
			email,
			password,
			names,
			surnames: surnames,
			documentType: type_id,
			identificationCard: id,
			role,
			managerId,
			planta: plant
		}
	});
}

export async function changeStatus(id, status){
	return request({
		host: 'https://api.palmares.co/',
		url: 'users/updateStateUser',
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'POST',
		body: {
			state: status,
  		idUser: id
		}
	});
}


export async function update(user){
	return request({
		host: 'https://api.palmares.co/',
		url: `users/${user.id}`,
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'PUT',
		body: {
     ...user
		}
	});
}


export async function remove(id){
	return request({
		host: 'https://api.palmares.co/',
		url: `users/${id}`,
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'DELETE',
		body: {}
	});
}

