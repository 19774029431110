import * as constants from '../constants/providers.constants';

const providersReducer = (
	state = { providers: [] },
	action,
) => {
	switch (action.type) {
		case constants.LOAD_PROVIDERS:
			return { providers: action.payload };

		default:
			return state;
	}
};

export default providersReducer;
