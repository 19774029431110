import request from '../utils/request.util';

export async function signin(email, password) {
	return request({
		host: 'https://api.palmares.co/',
		url: 'auth/sign-in',
		method: 'POST',
		body: {
			email,
			password,
		},
	});
}

export async function signup(email, password) {
	return request({
		host: '',
		url: '',
		body: {
			email,
			password
		},
	});
}

export async function updatepass(email){
	return request({
		host: 'https://api.palmares.co/',
		url: `auth/sign-in/'${email}`,
		method: 'POST',
		body: {
			email,
		},
	});
}
