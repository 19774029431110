import request from '../utils/request.util';

export async function getPayments(nit, plant, dateRange){

	return request({
		host:  'https://api.palmares.co/',
		url: 'soap/getHistoricoPagos',
		method: 'POST',
		body: {
			planta: plant,
			nitproveedor: nit,
			fechadesde: dateRange.start,
			fechahasta: dateRange.end
		}
	});
}

export async function getKilos(nit, plant, dateRange){
	return request({
		host:  'https://api.palmares.co/',
		url: 'soap/getCantidadKilosProveedor',
		method: 'POST',
		body: {
			planta: plant,
			nitproveedor: nit,
			fechadesde: dateRange.start,
			fechahasta: dateRange.end
		}
	});
}

export async function getFertilizantes(nit, plant, date){
	return request({
		host:  'https://api.palmares.co/',
		url: 'soap/getInformacionFertilizante	',
		method: 'POST',
		body: {
			planta: plant,
			nitproveedor: nit,
			fechahasta: date,
		}
	});
}
