import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { ActivityContent } from './activityContent'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import DatePicker from '../atoms/datePicker.atom';
import { Loading3 } from '../loading.component';
import ActivityForm from './activityForm.component';
import repository from '../../repository';
import api from '../../api';
import { connect } from 'react-redux';

class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedOption: null,
      weekendsVisible: true,
      currentEvents: [],
      show: false,
      _id: '',
      title: '',
      startDate: '',
      endDate: '',
      description: '',
      user: null,
      provider: null,
      allDay: false,
      activities: [],
      users: [],
      providers: [],
      form: null,
      completed: true,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.save = this.save.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }


  async componentDidMount() {
    // api.users.getAdvisers(localStorage.id).then((r) => {
    //   this.setState({ users: r.data });
    // });

    repository.visitors.get(localStorage.id).subscribe((r) => {
      this.setState({ users: r });
    })
    
    
    this.setState({ providers: this.props.providers, loading: true });
    api.providers.getProviders().then((r) => {
      const { provider } = this.state;
      if (provider && provider !== '') {
        const selectedProvider = _.find(r.data, { id:provider })
        this.setState({
          selectedOption: { value: selectedProvider?.id, label : selectedProvider?.representative_name },
        });
      }
      this.setState({ providers: r.data, loading: false });
    });

    repository.activities.getByManagerId(localStorage.id).subscribe((activities) => {
      this.setState({ activities });
    });
  }

  handleEventClick(e) {
    const { providers } = this.state;
    
    repository.activities.getActivityById(e.event.id).then((a) => {
      const activity = a.data();

      repository.activities.getFormByActivityId(e.event.id).subscribe((form) => {
        form.map((f) => {
          if (activity.completed) {
            this.setState({ form: f })
          }
        })
      });

      const selectedProvider = _.find(providers, { id: activity.provider })

      this.setState({
        _id: a.id,
        title: activity.title,
        startDate: activity.startDate,
        endDate: activity.endDate,
        description: activity.description,
        user: activity.user,
        provider: activity.provider,
        selectedOption: { value: selectedProvider?.id, label : selectedProvider?.representative_name },
        allDay: activity.allDay,
        completed: activity.completed,
      });
    })
    this.handleShow();
  }
  
  handleDateSelect(e) {
    this.setState({
      startDate: e.startStr,
      endDate: e.endStr,
      allDay: e.allDay,
      show: true,
    });
  }

  save(e) {
    const { title, startDate, endDate, description, user, provider, allDay } = this.state;
    
    const save = {
      title,
      startDate,
      endDate,
      description,
      user,
      provider,
      allDay,
    };
    
    save.plant = '';

    
    repository.visitors.getById(user).then((r) => {
      if (localStorage.role === 'director' && localStorage.plant) {
        save.plant = localStorage.plant;
        save.director = localStorage.id
      }
      
      if (localStorage.role === 'gerente' && localStorage.plt) {
        const visitor = r.data();
        save.plant = localStorage.plt === 'tumaco' ? 'TCO' : 'ESC';
        save.gerente = localStorage.id;
        save.director = visitor.managerId;
      }
  
      repository.activities.save(save);
    })


    this.handleClose();
  }

  update(e) {
    const { title, startDate, endDate, description, user, allDay, provider, _id } = this.state;

    let plant = '';

    if (localStorage.role === 'director' && localStorage.plant) {
      plant = localStorage.plant;
    }

    if (localStorage.role === 'gerente' && localStorage.plt) {
      plant = localStorage.plt === 'tumaco' ? 'TCO' : 'ESC';
    }

    repository.activities.update({
      title,
      startDate,
      endDate,
      description,
      user,
      provider,
      allDay,
      plant,
    }, _id);

    this.handleClose();
  }

  delete(e) {
    const { _id } = this.state;

    repository.activities.remove(_id);

    this.handleClose();
  }

  handleClose () {
    this.setState({
      _id: '',
      title: '',
      startDate: '',
      endDate: '',
      description: '',
      user: "",
      provider: "",
      selectedOption: null,
      allDay: false,
      show: false,
      form: null,
    });
  }

  handleShow () {
    this.setState({ show: true });
  }

  handleChange(selectedOption) {
    this.setState({ provider: selectedOption.value, selectedOption });  
  }

  render() {

    const { show, startDate, endDate, allDay, activities, _id, title, user, description, users, provider, form, completed, loading, providers, selectedOption } = this.state;

    const disabled = isEmpty(title) || isEmpty(endDate) || isEmpty(startDate) || isEmpty(provider) || isEmpty(description);

    return (
      <>
        <div className="relative flex flex-wrap mt-4 " style={{ marginTop: '-150px', zIndex: 1001}}>
          <div className="w-full mb-12 px-4">
            <Dialog
              open={show}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-slide-title">Actividad: {
                allDay
                ? moment(startDate).locale('es').format('DD/MM/YYYY') :
                `${moment(startDate).locale('es').format('DD/MM/YYYY\xa0\xa0hh:mm A')} - ${moment(endDate).locale('es').format('DD/MM/YYYY\xa0\xa0hh:mm A')}` }
                {
                  provider ? (
                    <div>
                      <p>Proveedor: {_.find(providers, { nit: provider })?.nit } | {_.find(providers, { nit: provider })?.plant } | {_.find(providers, { nit: provider })?.representative_name }</p>
                    </div>
                  ) : null
                }
              </DialogTitle>
              <DialogContent>
                <div>

                  <form>
                    <div className="flex flex-wrap mb-3 mt-3">
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          {
                            startDate ? (
                              <DatePicker onChange={(e) => this.setState({ startDate: e.target.value, allDay: false })} label='Fecha Inicio' value={moment(startDate).format('YYYY-MM-DDThh:mm')} />
                            ) : null
                          }
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          {
                            endDate ? (
                              <DatePicker onChange={(e) => this.setState({ endDate: e.target.value, allDay: false })} label='Fecha Fin' value={moment(endDate).format('YYYY-MM-DDThh:mm')} />
                            ) : null
                          }
                        </div>
                      </div>
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Titulo
                          </label>
                          <input
                            onChange={(e) => this.setState({ title: e.target.value })}
                            type="text"
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="Titulo"
                            value={title}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Asesor
                          </label>
                          <select
                            onChange={(e) => this.setState({ user: e.target.value })}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            value={user}
                          >
                            <option value="">Seleccione Usuario</option>
                            {
                              users.map((user, index) => {
                                return (<option key={`user-${user._id}${index}`} value={user._id}>{user.names}</option>)
                              })
                            }
                          </select>
                        </div>
                      </div>

                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2 flex flex-wrap items-center"
                            htmlFor="grid-password"
                          >
                            proveedores
                            { loading ? <Loading3 /> : null}
                          </label>
                          <Select
                            value={selectedOption}
                            onChange={this.handleChange}
                            options={providers.map((p) => {  return { value: p.id, label: p.representative_name }})}
                          />
                        </div>
                      </div>

                    
                      <div className="w-full lg:w12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Descripción
                          </label>
                          <textarea
                            value={description}
                            onChange={(e) => this.setState({ description: e.target.value })}
                            type="text"
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="Descripción"
                            rows="4"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </DialogContent>
              <DialogActions className="mr-5">
                <Button variant="contained" onClick={this.handleClose} color="secondary">
                  Cancelar
                </Button>
                 {
                   
                  _id !== '' &&  completed !== true ? (
                    <Button variant="contained" onClick={this.delete} color="secondary">
                      Eliminar
                    </Button>
                  ) : null
                } 

                {
                  _id !== '' ? (
                    <Button disabled={form === null ? false : true } variant="contained" onClick={this.update} color="primary">
                      Actualizar
                    </Button>
                  ) : (
                    <Button
                      disabled={disabled}
                      variant="contained"
                      onClick={this.save}
                      color="primary"
                      className={`${disabled ? 'disabled' : ''}`}
                    >
                      Guardar
                    </Button>
                  )
                }
                
              </DialogActions>

              <DialogContent>
                <hr />
                <ActivityForm form={form} />
              </DialogContent>
            </Dialog>
            <div className='demo-app custom-bg-white'>
              <div className='demo-app-main'>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: 'prev,next,today',
                    center: 'title',
                    right: 'timeGridWeek,timeGridDay,dayGridMonth'
                  }}
                  initialView='dayGridMonth'
                  editable={false}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  weekends={this.state.weekendsVisible}
                  events={ 
                    activities.map((activity) => { 
                      var startDate = new Date(activity.endDate)
                      var nowDate = new Date();
                      let color = 'default';
                      if(activity.completed){
                        color = 'green'
                      } else if( nowDate.getTime() > startDate.getTime() ){
                        color = 'red'
                      }
                      return { id: activity._id, title: activity.title, start: moment(activity.startDate).format("YYYY-MM-DD"), end: moment(activity.endDate).format("YYYY-MM-DD"), color: color }
                    })
                  }
                  select={this.handleDateSelect}
                  eventContent={ActivityContent} // custom render function
                  eventClick={this.handleEventClick}
                  eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    providers: state.providers.providers,
  }
}

export default connect(mapStateToProps, {})(Activities);
