import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { db } from './firestore.config';

export const getVisitors = () => {
	return new Observable((observer) => db.collection('visitors')
		.onSnapshot(observer))
		.pipe(
			map((data) => {
				return data.docs.map((d) => {
					return { _id: d.id, ...d.data()};
				});
			}),
		);
};

export const get = (id) => {
	if (localStorage.role === 'gerente' && localStorage.plt) {
		return new Observable((observer) => db.collection('visitors')
		.where('plant', '==', localStorage.plt === 'tumaco' ? 'TCO' : 'ESC')
		.onSnapshot(observer))
		.pipe(
			map((data) => {
				return data.docs.map((d) => {
					return { _id: d.id, ...d.data()};
				});
			}),
		);
	} else if (localStorage.role === 'director' && localStorage.plant) {
		return new Observable((observer) => db.collection('visitors')
		.where('plant', '==', localStorage.plant)
		.where('managerId', '==', localStorage.id)
		.onSnapshot(observer))
		.pipe(
			map((data) => {
				return data.docs.map((d) => {
					return { _id: d.id, ...d.data()};
				});
			}),
		);
	} else {
		return new Observable((observer) => db.collection('visitors')
		.onSnapshot(observer))
		.pipe(
			map((data) => {
				return data.docs.map((d) => {
					return { _id: d.id, ...d.data()};
				});
			}),
		);
	}

	
};

export const getById = (_id) => {
	return db.collection('visitors').doc(_id).get();
};

export const save = (visitors) => {
	return db.collection('visitors').add({
		...visitors,
		created_at: new Date(),
		updated_at: new Date(),
	});
};

export const update = (visitor, _id) => {
	return db.collection('visitors').doc(_id).update({
		...visitor,
		updated_at: new Date(),
	});
};

export const changeStatus = (_id, status) => {
	return db.collection('visitors').doc(_id).update({
		status,
		updated_at: new Date(),
	});
};


export const login = (email, password) => {
	return new Observable((observer) => db.collection('visitors')
		.where('email', '==', email)
    .where('password', '==', password)
		.onSnapshot(observer))
		.pipe(
			map((data) => {
				return data.docs.map((d) => {
					return { _id: d.id, ...d.data()};
				});
			}),
		);
};