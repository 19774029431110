import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty, isArray } from 'lodash';
import swal from 'sweetalert';

import api from '../../api';

export default function Login() {
  const history = useHistory();

  const [nit, setNit] = useState('');
  const [verfiy, setVerify] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [provider, setProvider] = useState('');

  const login = async () => {
    localStorage.clear();
    api.providers.verifyProviderByNit(nit).then((r) => {
      swal("El proveedor ya se encuentra registrado, intenta iniciar sesión como usuario estandar", "", "error");
      history.push('/Login');
    }).catch(async (err) => {
      if (err?.response?.status === 404) {
        const res = await api.providers.getProviderByNit('TCO', nit);
        if (res.data.length === 0) {
          const res2 = await api.providers.getProviderByNit('ESC', nit);
          if ((res2.data.length === 0) || (res2.data.length > 0 && res2.data[0].email !== email)) {
            swal("No se encontro proveedor con el nit y correo ingresado", "", "error");
          } else {
						swal("El proveedor ingresado Existe en ESC, por favor complete el formulario", "", "success");
						setEmail(res2.data[0].email)
						setProvider(res2.data[0])
						setVerify(true)
          }
        } else {
					if ((res.data.length === 0) || (res.data.length > 0 && res.data[0].email !== email)) {
            swal("No se encontro proveedor con el nit y correo ingresado", "", "error");
          } else {
						swal("El proveedor ingresado Existe en TCO, por favor complete el formulario", "", "success");
						setEmail(res.data[0].email)
						setProvider(res.data[0])
						setVerify(true)
					}
        }
      }
    })
  }


  const signupProvider = () => {
    const names = `${provider.primerNombre} ${provider.segundoNombre}`;
    const surnames = `${provider.primerApellido} ${provider.segundoApellido}`;
    const id = nit;
    const documentType = provider.tipoIdentificacion;
    const plant = provider.plant;

    api.users
      .save(email, password, names, surnames, id, documentType, 'proveedor', '0', plant)
      .then((r) => {
        swal("El proveedor se guardo correctamente, por favor intente inciar sesion con sus datos", "", "success");
        history.push('/Login');
      }).catch((err) => {
        swal('Error en el formulario, por favor validar', "", "error");
      });
  }

  let disabled = false;

  if (verfiy) {
    disabled = isEmpty(email) || isEmpty(password);
  } else {
    disabled = isEmpty(nit) || isEmpty(email);
  }

  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundColor: '#8d8d8d'

            }}
            // style={{
            //   backgroundImage:
            //     "url(" + require("assets/img/register_bg_2.png") + ")",
            // }}
          ></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3 flex items-center justify-center">
                    <img
                        alt="..."
                        className="m-2"
                        width="100"
                        src={require("assets/img/logo-palmas.png")}
                      />
                    <img
                        alt="..."
                        className="m-2"
                        width="80"
                        src={require("assets/img/logo.png")}
                      />
                    </div>

                    <hr className="mt-6 border-b-1 border-gray-400" />
                    {
                      verfiy ? (
                        <>
                          <br />
                          <center><p>Hola, <b>{provider.representative_name}</b></p> </center>
                        <hr className="mt-6 border-b-1 border-gray-400" />
                        </>
                      ) : null
                    }
                   
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          NIT
                        </label>
                        <input
                          onChange={(e) => setNit(e.target.value)}
                          type="number"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="NIT"
                        />
                      </div>

                      
											<div className="relative w-full mb-3">
												<label
													className="block uppercase text-gray-700 text-xs font-bold mb-2"
													htmlFor="grid-password"
												>
													Correo
												</label>
												<input
													onChange={(e) => setEmail(e.target.value)}
													type="email"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="Email"
													value={email}
												/>
											</div>


                      {
                        verfiy ? (
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Contraseña
                            </label>
                            <input
                              onChange={(e) => setPassword(e.target.value)}
                              type="password"
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              placeholder="Password"
                            />
                          </div>
                        ) : null
                      }

                      {
                        verfiy ? (
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Confirmar Contraseña
                            </label>
                            <input
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              type="password"
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              placeholder="Password"
                            />
                          </div>
                        ) : null
                      }

                      
                      

                      <div className="text-center mt-6">
                        <button
                          disabled={disabled}
                          onClick={() => {
                            if (verfiy) {
                              signupProvider();
                            } else {
                              login()
                            }
                          }}
                          className={`${disabled ? 'disabled' : ''} bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                          type="button"
                        >
                          { verfiy ? 'Registrar' : 'Verificar usuario' } 
                        </button>
                        <button
                          onClick={() => history.push('/login')}
                          className={`bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                          type="button"
                        >
                          Iniciar como usuario estandar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
