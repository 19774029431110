import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty, isArray } from "lodash";
import swal from "sweetalert";

import api from "../../api";

export default function Login() {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!isEmpty(localStorage.getItem("token"))) {
      if (
        localStorage.getItem("role") === "gerente" ||
        localStorage.getItem("role") === "director"
      ) {
        history.push("/admin/actividades");
      }
      if(
        localStorage.getItem("role") === "admin" ||
        localStorage.getItem("role") === "proveedor"
      ){
        history.push("/admin/dashboard");
      }
    }
  }, []);

  const login = () => {
    localStorage.clear();
    api.auth
      .signin(email, password, ["admin", "gerente", "director"])
      .then(({ data }) => {
        localStorage.setItem("token", data.accessToken);
        localStorage.setItem("email", data.loginPayload.email);
        localStorage.setItem("surnames", data.loginPayload.surnames);
        localStorage.setItem("names", data.loginPayload.names);
        localStorage.setItem("id", data.loginPayload.id);
        localStorage.setItem("role", data.loginPayload.role);
        localStorage.setItem("plt", "tumaco");

        if (
          data.loginPayload.role === "gerente" ||
          data.loginPayload.role === "director"
        ) {
          localStorage.setItem("plant", data.loginPayload.planta);
          history.push("/admin/actividades");
        }

        if (data.loginPayload.role === "proveedor") {
          localStorage.setItem("plant", data.loginPayload.planta);
          localStorage.setItem(
            "providerId",
            data.loginPayload.identificationCard
          );
          localStorage.setItem("nit", data.loginPayload.identificationCard);
          history.push("/admin/dashboard");
        }

        if (data.loginPayload.role === "admin") {
          history.push("/admin");
        }
      })
      .catch((err) => {
        swal(
          "No se pudo iniciar sesión, por favor valide las credenciales",
          "",
          "error"
        );
      });
  };

  const disabled = isEmpty(email) || isEmpty(password);

  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundColor: "#8d8d8d",
            }}
            // style={{
            //   backgroundImage:
            //     "url(" + require("assets/img/register_bg_2.png") + ")",
            // }}
          ></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3 flex items-center justify-center">
                      <img
                        alt="..."
                        className="m-2"
                        width="100"
                        src={require("assets/img/logo-palmas.png")}
                      />
                      <img
                        alt="..."
                        className="m-2"
                        width="80"
                        src={require("assets/img/logo.png")}
                      />
                    </div>

                    <hr className="mt-6 border-b-1 border-gray-400" />
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Correo
                        </label>
                        <input
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="Email"
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Contraseña
                        </label>
                        <input
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="Password"
                        />
                      </div>

                      <div className="text-center mt-6">
                        <button
                          disabled={disabled}
                          onClick={() => login()}
                          className={`${
                            disabled ? "disabled" : ""
                          } bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                          type="button"
                        >
                          Iniciar sesion
                        </button>
                        <button
                          onClick={() => history.push("/login-proveedores")}
                          className={`bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                          type="button"
                        >
                          Iniciar primera vez como proveedor
                        </button>
                        <button
                          onClick={() => history.push("/recuperar-contraseña")}
                          className={`bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                          type="button"
                        >
                          Recuperar Contraseña
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
