import React, { Component } from 'react';
import CardTableFertilizante from "components/Cards/CardTableFertilizante";
import DataTable from 'react-data-table-component';
import Loading from '../loading.component';
import DataTableExtensions from 'react-data-table-component-extensions';

import xml2js from 'xml2js';
import moment from 'moment';
import { isEmpty } from 'lodash';
import api from '../../api';

const columns = [
  {
    name: 'fecha',
    selector: 'fecha',
    sortable: true,
  },
  {
    name: 'Valor Factura',
    selector: 'valorFactura',
    sortable: true,
    right: true,
  },
  {
    name: 'Saldo Factura',
    selector: 'saldoFactura',
    sortable: true,
    right: true,
  },
];

class fertilizante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fertilizante: [],
    };

    this.getFertilizantes = this.getFertilizantes.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
  }

  async componentDidMount() {
    if (localStorage.role === 'proveedor') {
      this.getFertilizantes(
        moment(new Date()).locale('es').format('YYYY-MM-DD'),
        localStorage.nit,
        localStorage.plant,
      );
    }
  }


  formatNumber(num) {
    if (!num || num == 'NaN') return '-';
    if (num == 'Infinity') return '&#x221e;';
    num = num.toString().replace(/\$|\\,/g, '');
    if (isNaN(num))
        num = "0";
    let sign = (num == (num = Math.abs(num)));
    num = Math.floor(num * 100 + 0.50000000001);
    let cents = num % 100;
    num = Math.floor(num / 100).toString();
    if (cents < 10)
        cents = "0" + cents;
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3) ; i++)
        num = num.substring(0, num.length - (4 * i + 3)) + '.' + num.substring(num.length - (4 * i + 3));
    return (((sign) ? '' : '-') + num + ',' + cents);
  }

  async getFertilizantes(
    
    date = moment(new Date()).locale('es').format('YYYY-MM-DD'),
    nit = '',
    plant = ''
  ) {
    this.setState({ loading: true });

    const fertilizante = [];

    
    if ((!isEmpty(nit) && !isEmpty(plant)) || (localStorage.role && localStorage.role === 'proveedor')) {
      let res = null;

      if (localStorage.role && localStorage.role === 'proveedor') {
        res = await api.payments.getFertilizantes(localStorage.nit, localStorage.plant, date);      
      } else {
        if (!isEmpty(nit) && !isEmpty(plant)) {
          res = await api.payments.getFertilizantes(nit, plant, date);
        }
      }

      if (res !== null) {
        xml2js.parseString(res.data, {trim: true, explicitArray: true}, (err, result) => {
          if(err) {
            throw err;
          }
    
          if (result['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['WsProduccionSDT.INFORMACIONFERTILIZANTEResponse'][0]['Sdtfacturafertilizante'][0]['SDTFacturaFertilizanteItem']) {
            result['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['WsProduccionSDT.INFORMACIONFERTILIZANTEResponse'][0]['Sdtfacturafertilizante'][0]['SDTFacturaFertilizanteItem'].map((item) => {
              fertilizante.push({
                  fecha: item.Fecha_Factura,
                  valorFactura:  item.Valor_Factura,
                  saldoFactura: this.formatNumber(item.Saldo_Factura),
                  numeroFactura:  item.Numero_Factura,
                });
              })
              this.setState({ fertilizante, loading: false });
          } else {
            this.setState({ fertilizante: [], loading: false });
          }
        });
      }
    } else {
      this.setState({ fertilizante: [], loading: false });
    }
  }

  onChangeFilter(date, provider) {
    if (
      (provider && provider.nit && !isEmpty(provider.nit)) || 
      (provider && provider.plant && !isEmpty(provider.plant))
    ) {
      this.getFertilizantes(
        moment(date).locale('es').format('YYYY-MM-DD'),
        provider.nit,
        provider.plant,
      );
    }

    if (localStorage.role === 'proveedor') {
      this.getFertilizantes(
        moment(date).locale('es').format('YYYY-MM-DD'),
        localStorage.nit,
        localStorage.plant,
      );
    }
  }

  render() {
    const { fertilizante, loading } = this.state;

    return (
      <>
        <div style={{ marginTop: '-110px', zIndex: 1001}} className="flex flex-wrap mt-4">
          <div className="w-full mb-12  px-4">
            <CardTableFertilizante title="fertilizante Proveedores" onChangeFilter={this.onChangeFilter} fertilizante={fertilizante} loading={loading} />
            <DataTableExtensions
              columns={columns}
              data={fertilizante}
              exportHeaders
              filter
              filterPlaceholder="Buscar"
            >
              <DataTable
                title="fertilizante Proveedores"
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover
              />
            </DataTableExtensions>

            {
              loading ? (<center><Loading /></center>): null
            }
            
          </div>
        </div>
      </>
    )
  }
}

export default fertilizante;
