import request from '../utils/request.util';

export async function getMaterials(){
	return request({
		host: 'https://api.palmares.co/',
		url: 'materials',
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'GET',
	});
}

export async function getTotal(){
	return request({
		host: 'https://api.palmares.co/',
		url: 'materials/getTotalMaterials',
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'GET',
	});
}



export async function getById(id){
	return request({
		host: 'https://api.palmares.co/',
		url: `materials/${id}`,
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'GET',
	});
}

export async function save(name, code, price){
	return request({
		host: 'https://api.palmares.co/',
		url: 'materials',
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'POST',
		body: {
      name,
      codigo: code,
      price: Number(price)
		}
	});
}


export async function update(material){
	return request({
		host: 'https://api.palmares.co/',
		url: `materials/${material.id}`,
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'PUT',
		body: {
     ...material
		}
	});
}


export async function remove(id){
	return request({
		host: 'https://api.palmares.co/',
		url: `materials/${id}`,
		headers: {
			Authorization: `Bearer ${localStorage.token}`,
		},
		method: 'DELETE',
		body: {}
	});
}

