import firebase from 'firebase';

const firebaseConfig = {
	apiKey: "AIzaSyDfOn7nFy5ey56o6cEsPYmHMKsx0SrW4Fg",
  authDomain: "palmas-extrema.firebaseapp.com",
  projectId: "palmas-extrema",
  storageBucket: "palmas-extrema.appspot.com",
  messagingSenderId: "1012377297508",
  appId: "1:1012377297508:web:2092ebc280ffef6d04b571",
  measurementId: "G-WFMCPGF5YV"
};

firebase.initializeApp(firebaseConfig);

export const firebaseAdmin = firebase;
export const db = firebase.firestore();
export const storage = firebase.storage();
