import { combineReducers } from 'redux';

import providers from './providers.reducer';
import plant from './plant.reducer';

const reducers = combineReducers({
	providers,
	plant
});

export default reducers;
