import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Loading from '../loading.component';
import { Link } from "react-router-dom";
import _ from 'lodash';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import repository from '../../repository';

class Visitors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visitors: [],
      loading: true,
      columns: [
        {
          name: 'Correo Electrónico',
          selector: 'email',
          sortable: true,
          cell: d => <div className="mw-200 worldWrap">{d.email}</div>,
        },
        {
          name: 'identificación',
          selector: 'identificationCard',
          sortable: true,
          right: true,
        },
        {
          name: 'Nombre',
          selector: 'names',
          sortable: true,
          right: true,
        },
        {
          name: 'Apellido',
          selector: 'surnames',
          sortable: true,
          right: true,
          cell: d => <div className="mw-200 worldWrap">{d.surnames}</div>,
        },
        {
          name: 'Estado',
          selector: 'status',
          sortable: true,
          right: true,
          cell: d => <div className="mw-200 worldWrap">{d.status ? 'Activo' : 'Inactivo'}</div>,
        },
        {
          name: 'Editar',
          sortable: true,
          center: true,
          cell: row => (<div className="flex align-items justify-center">
            <button className="bg-white flex align-items justify-center shadow-lg m-1 text-black font-bold rounded-2 p-2 " onClick={() => this.edit(row._id)}>
              <i className="text-center fas fa-edit text-sm text-black"></i>
            </button>
            
          </div>)
        },
        {
          name: 'Activar/Inactivar',
          sortable: true,
          center: true,
          cell: row => (<div className="flex align-items justify-center">
            <button onClick={() => this.changeStatus(row._id)}  className="p-2  m-1 bg-white flex align-items justify-center shadow-lg  text-black font-bold rounded-2">
              <i class="fas fa-exchange-alt text-sm text-black text-center"></i>
            </button>    
          </div>)
        },
      ]
    };
    this.edit = this.edit.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }


  async componentDidMount() {
    this.setState({ loading: true });
    repository.visitors.get().subscribe((visitors) => {
      this.setState({ visitors, loading: false });
    });; 
  }

  edit = (id=null) => {
    const { history } = this.props;

    if (id !== null) {
      history.push(`/admin/editar-asesores/${id}`);
    }
  }

  changeStatus = (_id=null) => {
    const { visitors } = this.state;

    if (_id !== null) {
      const index = _.findIndex(visitors, { _id });
      const status = !visitors[index].status;
      repository.visitors.changeStatus(_id, status);
    }
  }

  render() {
    const { visitors, loading, columns } = this.state;

    return (
      <>
        <div style={{ marginTop: '-150px', zIndex: 1001}} className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            <Link
              className="position-absolute my-5-reverse bg-white text-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              to="/admin/registrar-asesores"
            >
              Crear Asesor
            </Link>
            {/* <CardTableUsers title="Usuarios" setUsers={this.setUsers} users={users} loading={loading} /> */}
            <DataTableExtensions
              columns={columns}
              data={visitors}
              exportHeaders
              filter
              filterPlaceholder="Buscar"
            >
              <DataTable
                title="Asesores"
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover
              />
            </DataTableExtensions>
            {
              loading ? (
                <center className="m-4"><Loading /></center>
              ) : null
            }
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Visitors);
