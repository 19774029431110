import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import _, { isArray } from "lodash";
import repository from "../../repository";

import swal from "sweetalert";

import api from "../../api";

export default function AddUser() {
  const [id, setId] = useState("");
  const [names, setNames] = useState("");
  const [surnames, setSurnames] = useState("");
  const [email, setEmail] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [role, setRole] = useState("");
  let [managerId, setManagerId] = useState("");
  const [managers, setManagers] = useState([]);
  const [password, setPassword] = useState("");
  let [plant, setPlant] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    api.users.getByRole("director").then((r) => {
      setManagers(r.data);
    });

    if (params.id) {
      api.users.getById(params.id).then((r) => {
        setId(r.data.identificationCard);
        setNames(r.data.names);
        setSurnames(r.data.surnames);
        setEmail(r.data.email);
        setDocumentType(r.data.documentType);
        setRole(r.data.role);
        setManagerId(r.data.managerId);
        setPlant(r.data.planta);
      });
    }
  }, []);

  const save = () => {
    if (localStorage.role === "gerente") {
      plant = localStorage.plt === "tumaco" ? "TCO" : "ESC";
    }

    if (localStorage.role === "director") {
      plant = localStorage.plant;
      managerId = localStorage.id;
    }

    if (role === "gerente") {
      plant = "";
    }

    if (role === "admin") {
      plant = "";
    }

    if (params.id) {
      if(password === "") {
        api.users
        .update({
          email,
          names: names,
          surnames,
          id: params.id,
          documentType: documentType,
          identificationCard: id,
          role,
          managerId,
          planta: plant,
        })
        .then(() => {
          history.push("/admin/usuarios");
        })
        .catch((err) => {
          swal("Error en el formulario, por favor validar", "", "error");
        });
      } else {
        if(password !== confirmPassword){
          swal("por favor validar la contraseña", "", "error");
          return;
        }
        api.users
        .update({
          email,
          names: names,
          surnames,
          id: params.id,
          documentType: documentType,
          identificationCard: id,
          role,
          managerId,
          planta: plant,
          password
        })
        .then(() => {
          history.push("/admin/usuarios");
        })
        .catch((err) => {
          swal("Error en el formulario, por favor validar", "", "error");
        });
      }
  
    } else {
      if (role === "asesor") {
        if(password !== confirmPassword){
          swal("por favor validar la contraseña", "", "error");
          return;
        }
        repository.visitors
          .save({
            email,
            password,
            names,
            surnames,
            documentType,
            role,
            managerId,
            plant,
          })
          .then((r) => {
            history.push("/admin/usuarios");
          });
      } else {
        if(password !== confirmPassword){
          swal("por favor validar la contraseña", "", "error");
          return;
        }
        api.users
          .save(
            email,
            password,
            names,
            surnames,
            id,
            documentType,
            role,
            managerId,
            plant
          )
          .then((r) => {
            history.push("/admin/usuarios");
          })
          .catch((err) => {
            swal("Error en el formulario, por favor validar", "", "error");
          });
      }
    }
  };

  _.find(managers, { managerId });

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">
              {params.id ? `Editar Usuario` : `Registrar Usuario`}{" "}
            </h6>
          </div>
        </div>
        <div></div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex flex-wrap mb-3 mt-3">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nombre
                  </label>
                  <input
                    onChange={(e) => setNames(e.target.value)}
                    value={names}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Nombre"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Apellido
                  </label>
                  <input
                    onChange={(e) => setSurnames(e.target.value)}
                    value={surnames}
                    placeholder="Apellido"
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Correo electrónico
                  </label>
                  <input
                    placeholder="Correo electrónico"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="email"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Tipo de identificación
                  </label>
                  <select
                    onChange={(e) => setDocumentType(e.target.value)}
                    value={documentType}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  >
                    <option value="">Seleccione tipo de identificación</option>
                    <option value="CC">Cedula de ciudadania</option>
                    <option value="TI">Tarjeta de identidad</option>
                    <option value="CE">Cedula extranjeria</option>
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Identificación
                  </label>
                  <input
                    placeholder="Identificación"
                    onChange={(e) => setId(e.target.value)}
                    value={id}
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Rol
                  </label>
                  <select
                    onChange={(e) => {
                      setRole(e.target.value);
                      setManagerId("");
                    }}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  >
                    {params.id ? <option value={role}>{role}</option> : null}
                    <option value="">Seleccione un role</option>
                    {localStorage.role !== "gerente" &&
                    localStorage.role !== "director" ? (
                      <option value="admin">Administrador</option>
                    ) : null}
                    {localStorage.role !== "gerente" &&
                    localStorage.role !== "director" ? (
                      <option value="gerente">Gerente</option>
                    ) : null}
                    {localStorage.role !== "director" ? (
                      <option value="director">Director</option>
                    ) : null}
                  </select>
                </div>
              </div>
              {role === "asesor" && localStorage.role === "gerente" ? (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Director
                    </label>
                    <select
                      value={managerId}
                      onChange={(e) => setManagerId(e.target.value)}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    >
                      <option value="">Seleccione un role</option>
                      {managers.map((m, i) => {
                        return (
                          <option
                            className="capitalize"
                            key={`gerente-${m.id}-${i}`}
                            value={m.id}
                          >
                            {m.names}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              ) : null}

              {localStorage.role === "admin" &&
              role !== "gerente" &&
              role !== "admin" ? (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Planta
                    </label>
                    <select
                      onChange={(e) => setPlant(e.target.value)}
                      value={plant}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    >
                      <option value="">Seleccione tipo de planta</option>
                      <option value="ESC">ESC</option>
                      <option value="TCO">TCO</option>
                    </select>
                  </div>
                </div>
              ) : null}

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Contraseña
                  </label>
                  <input
                    placeholder="Contraseña"
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Confirmar Contraseña
                  </label>
                  <input
                    placeholder="Confirmar Contraseña"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>
            <div className="text-center mt-6">
              <button
                onClick={() => save()}
                className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="button"
              >
                Guardar Usuario
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
