import React, { useEffect } from "react";
import exportFromJSON from "export-from-json";
import _ from "lodash";
import repository from "../../repository";
import Iframe from "react-iframe";


export default function Dashboard() {
  const descargarInfo = () => {
    repository.activities.getAllFormWithActivity().then((data) => {
      const forms = data.docs.map((d) => {
        const d2 = d.data();
        if( d2.plateos == "1"){
          d2.plateos = "Deficiente";
        }else if(d2.plateos == "2"){
          d2.plateos = "Por mejorar";
        }else if(d2.plateos == "3"){
          d2.plateos = "Aceptable";
        }else if(d2.plateos == "4"){
          d2.plateos = "Óptimo";
        }else if(d2.plateos == "5"){
          d2.plateos = "Excelente";
        }

        if( d2.podas == "1"){
          d2.podas = "Deficiente";
        }else if(d2.podas == "2"){
          d2.podas = "Por mejorar";
        }else if(d2.podas == "3"){
          d2.podas = "Aceptable";
        }else if(d2.podas == "4"){
          d2.podas = "Óptimo";
        }else if(d2.podas == "5"){
          d2.podas = "Excelente";
        }


        return {
          //nuevos prueba Nombre - apellido - fecha carga
          "NOMBRE USUARIO": d2.names,
          "APELLIDO USUARIO": d2.surnames,
          "FECHA CARGA": d2.updated_at.toDate(),
          "PLANTA": d2.proveedor.plant,
          "NIT": d2.proveedor.nit,
          "NOMBRE PROVEEDOR": d2.proveedor.representative_name,
          "HECTAREAS": d2.proveedor.hectareas,
          ESTADO: d2.mejora,
          PLATEOS: d2.plateos,
          PODAS: d2.podas,
          "PRECENSIA GRAMINEAS": d2.precensia_gramineas,
          "COMPETENCIA ARVENSES": d2.competencia_arvenses,
          NECTARIFERAS: d2.nectariferas,
          RECOMENDACIONES: d2.recomendaciones,
          "APLICA MATERIA ORGANICA": d2.se_fertiliza,
          "KG MATERIA ORGANICA APLICADA": d2.kg_aplicados_actual,
          "APLICA FERTILIZANTE QUIMICO": d2.aplica_fertilizante_quimico,
          "KG APLICADOS AÑO ANTERIOR": d2.kg_aplicados_anterior,
          BORO: d2.boro,
          DAP: d2.dap,
          KCL: d2.kcl,
          KIESERITA: d2.kieserita,
          PALMERO: d2.palmero,
          [`${d2.otros_nombre.toUpperCase()}`]: d2.otros_valor,
          [`${d2.otros_nombre_2.toUpperCase()}`]: d2.otros_valor_2,
          "NO. APLICACIONES ENTRADAS ANUALES": d2.no_aplicaciones,
          "APLICA ENMIENDA": d2.aplica_enmienda,
          "KG ENMIENDA PALMA/AÑO": d2.kg_enmienda,
          "RECOMENDACIONES FERTILIZACION": d2.recomendaciones2,
          "FRECUENCIA CENSO SANITARIO": d2.frecuencia_censo_sanitario,
          "PUDRICION DE COGOLLO": d2.pudricion_de_cogollo,
          "PRACTICA CIRUGIA PC": d2.pudricion_de_cogollo_tiempo_censo,
          "INCIDENCIA PC": d2.pudricion_de_cogollo_incidencia,
          "ANILLO ROJO": d2.anillo_rojo,
          "TRAMPEO AR": d2.anillo_rojo_tiempo_censo,
          "INCIDENCIA AR": d2.anillo_rojo_incidencia,
          "MARCHITEZ Letal": d2.marchiez_letal,
          "INCIDENCIA ML": d2.marchiez_letal_incidencia,
          "ERRADICACIÓN ML": d2.marchiez_letal_tiempo_censo,
          SAGALASSA: d2.sagalassa,
          "MONITOREO SAGALASSA": d2.sagalassa_tiempo_censo,
          OPSIPHANES: d2.opsiphanes,
          "MONITOREO OPSIPHANES": d2.opsiphanes_tiempo_censo,
          OTRAS: d2.otras,
          "RECOMENDACIONES SANIDAD": d2.recomendaciones3,
          "NÚMERO RACIMOS DE LA MUESTRA": d2.no_racimos_muestra,
          "RACIMOS VERDES": d2.racimos_verdes,
          "RACIMOS PASADOS": d2.racimos_pasados,
          "RACIMOS PEDUNCULO LARGO": d2.pedunculo_largo,
          "RACIMOS ENFERMO": d2.racimos_enfermo,
          "RACIMOS MAL_POLONIZADOS": d2.racimos_mal_polonizados,
          "RACIMOS SOBREMADUROS": d2.racimos_sobremaduros,
          "DIAS CICLOS COSECHA": d2.ciclos,
          "RECOLECCIÓN DE FRUTO SUELTO": d2.recoleccion_de_fruto_suelto,
          "RECOMENDACIONES COSECHA": d2.recomendaciones4,
          "REGISTROS LABORES": d2.registros_labores,
          "EVIDENCIA TALA DE BOSQUES": d2.evidencia_tala_de_bosques,
          "EVIDENCIA PRACTICAS QUEMAS": d2.evidencia_practicas_quemas,
          "EVIDENCIA TOMA DE AGUA": d2.evidencia_toma_de_agua,
          "NO. TRABAJADORES DESTAJO": d2.no_trabajadores_destajo,
          "NO. TRABAJADORES PERMANENTES": d2.no_trabajadores_permanentes,
          "RECOMENDACIONES SOSTENIBILIDAD": d2.recomendaciones5,
          "HECTAREAS POLEN": d2.hectaresa_polen,
          "NO. ENTRADAS": d2.no_entradas,
          ANA: d2.ana,
          "NO ENTRADAS ANA": d2.no_entradas_ana,
          DOSIS: d2.dosis,
          "CICLOS REVISION INFLORESCENCIAS": d2.ciclos_revision_inflorescencias,
          "RECOMENDACIONES REGULADOR": d2.recomendaciones6,
          plant: d2.proveedor.plant,
        };
      });
      let newData = _.filter(forms, (form) => {
        return form.plant === (localStorage.plt === "tumaco" ? "TCO" : "ESC");
      });
      // const data = [];
      const fileName = "Reporte";
      const exportType = exportFromJSON.types.xls;
      exportFromJSON({ data: newData, fileName, exportType });
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      <div
        style={{ marginTop: "-150px", zIndex: 1001 }}
        className="flex flex-wrap"
      >
        <div className="w-full mb-12 px-4">
          <div style={{ marginTop: "-30px" }} className="flex flex-wrap mt-3">
            {localStorage.role !== "proveedor" ? (
              <div className="w-full lg:w-12/12">
                <div className="relative w-full">
                  <button
                    onClick={descargarInfo}
                    className=" my-5-reverse bg-white text-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  >
                    <i class="fas fa-download text-sm text-blue-500 text-center">
                      {" "}
                      Descargar Información
                    </i>
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <br></br>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          <Iframe
            //url="https://datastudio.google.com/embed/reporting/1e56c5f4-4fe9-4401-854a-dcd94dae8d9f/page/p_a82uiktmrc"
            url="https://extrema-online.com/dashboard-palmas/"
            width="100%"
            height="800px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          />
        </div>
      </div>
    </>
  );
}
