import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts
import Admin from "layouts/Admin.js";
import App from "components/app.component.js";

// views without layouts
import Profile from "views/Profile.js";
import Login from "views/auth/Login.js";
import LoginProviders from "views/auth/LoginProviders.js";
import RecoverPassword from "views/auth/RecoverPassword";

// import Register from "views/auth/Register.js";

import reducers from './reducers';

export const store = createStore(reducers, {}, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App>
        <Switch>
          {/* <Route path="/landing" exact component={Landing} /> */}
          <Route path="/profile" exact component={Profile} />
          <Route path="/admin" component={Admin} />
          <Route path="/login" exact component={Login} />
          <Route path="/login-proveedores" exact component={LoginProviders} />
          <Route path="/recuperar-contraseña" exact component={RecoverPassword} />
          
          {/* <Route path="/auth/register" exact component={Register} /> */}
          <Route path="/" exact component={Login} />
          <Redirect from="*" to="/" />
        </Switch>
      </App>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
