import React, { Component } from 'react';
import CardTableMaterials from "components/Cards/CardTableMaterials.js";

import api from '../../api';

class Materials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materials: [],
    };
    this.setMaterials = this.setMaterials.bind(this);
  }


  async componentDidMount() {
    const { data } = await api.materials.getMaterials();
    this.setState({materials: data});
  }

  setMaterials(materials = null) {
    if (materials !== null) {
      this.setState({ materials });
    }
  }

  render() {
    const { materials } = this.state;

    return (
      <>
        <div className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            <CardTableMaterials title="Materiales" setMaterials={this.setMaterials} materials={materials} />
          </div>
        </div>
      </>
    )
  }
}

export default Materials;
