import React, { Component } from 'react';
// import CardTableProviders from "components/Cards/CardTableProviders.js";
import { withRouter } from 'react-router-dom';
import Loading from '../loading.component';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import _ from 'lodash';
import api from '../../api';
class Providers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providers: [],
      plant: 'TCO',
      loading: false,
      ilterText: "", 
      resetPaginationToggle: false,
      columns: [
        {
          name: 'NIT del proveedor',
          selector: 'nit',
          sortable: true,
        },
        {
          name: 'Razón Social',
          selector: 'representative_name',
          sortable: true,
          right: false,
          cell: d => <div className="mw-200 worldWrap">{d.representative_name}</div>,
        },
        {
          name: 'Celular',
          selector: 'phone',
          sortable: true,
          right: true,
        },
        {
          name: 'Hectareas',
          selector: 'hectareas',
          sortable: true,
          right: true,
        },
        {
          name: 'Correo electrónico',
          selector: 'email',
          sortable: true,
          right: true,
          cell: d => <a href={`mailto:${d.email}`} className="mw-800 worldWrap">
            {d.email}
            </a>,
        },
        {
          name: 'Municipio',
          selector: 'city',
          sortable: true,
          right: true,
        },
        {
          name: 'Vereda',
          selector: 'vereda',
          sortable: true,
          left: true,
          cell: d => <div className="mw-800 worldWrap">
          {d.vereda}
          </div>,
        },
        {
          name: 'Ubicación',
          selector: 'address',
          sortable: true,
          left: true,
          cell: d => <div className="mw-200 worldWrap">{d.address}</div>,
          
        },
        // {
        //   name: 'Acciones',
        //   sortable: true,
        //   cell: row => (<div className="flex align-items justify-center">
        //     <button className="bg-white flex align-items justify-center shadow-lg m-1 text-black font-bold rounded-2 p-2 " onClick={() => this.edit(row.id)}>
        //       <i className="text-center fas fa-edit text-sm text-black"></i>
        //     </button>
        //     <button onClick={() => this.remove(row.id)}  className="p-2  m-1 bg-white flex align-items justify-center shadow-lg  text-black font-bold rounded-2">
        //       <i class="fas fa-trash-alt text-sm text-black text-center"></i>
        //     </button>    
        //   </div>)
        // },
      ],
    };
    
    this.setProviders = this.setProviders.bind(this);
    this.edit = this.edit.bind(this);
    this.remove = this.remove.bind(this);
    this.onChangePlant = this.onChangePlant.bind(this);
  }

  setProviders(providers = null) {
    if (providers !== null) {
      this.setState({ providers });
    }
  }

  async componentDidMount() {
    this.setState({ loading: true});
    const { data } = await api.providers.getProviders();
    this.setState({ providers: data, loading: false });
  }

  edit = (id=null) => {
    const { history } = this.props;

    if (id !== null) {
      history.push(`/admin/editar-proveedor/${id}`);
    }
  }
  
  remove = (id=null) => {
    const { providers } = this.state;

    if (id !== null) {
      api.providers.remove(id);
      _.remove(providers, { id });
      this.setProviders(providers);
    }
  }

  async onChangePlant(e) {
    this.setState({ providers: [], loading: true});
    const plt = this.state.plant === 'TCO' ? 'llanos' : 'tumaco';
    const param = plt === 'tumaco' ? "TCO" : "ESC";
    localStorage.setItem('plt', plt)
    const { data } = await api.providers.getProviders(param);
    this.setState({ providers: data, loading: false, plant: param });

  }

  render() {
    const { providers, loading, columns } = this.state;
    return (
      <>
        <div style={{ marginTop: '-50px', zIndex: 1001}} className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
          <div style={{ marginTop: '-60px' }} className="flex flex-wrap mb-3 mt-3">
              {
                localStorage.role === 'admin' ? (
                  <div className="w-full lg:w-4/12">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-white text-xs font-bold mb-2 flex flex-wrap items-center"
                        htmlFor="grid-password"
                      >
                        Planta
                      </label>
                      <select
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        onChange= {(e) => this.onChangePlant(e)}
                      >
                        <option value="TCO">TCO</option>
                        <option value="ESC">ESC</option>
                      </select>
                    </div>
                  </div>
                ) : null
              }
            </div>

            <DataTableExtensions
              columns={columns}
              data={providers}
              exportHeaders
              filter
              filterPlaceholder="Buscar"
            >
              <DataTable
                title="Proveedores"
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover
              />
            </DataTableExtensions>
            {
              loading ? (
                <center className="m-4"><Loading /></center>
              ) : null
            }
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Providers);
