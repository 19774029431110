import axios from 'axios';

async function request(configuration) {

	const fetch = {
		url: configuration.host + configuration.url,
		headers: configuration.headers || {
			"Access-Control-Allow-Origin": "*"
		},
		method: configuration.method || 'GET',
		data: configuration.body || {},
	};

	return axios(fetch)
		.then((res) => res.data)
		.catch((err) => {
			throw err;
		});
}

export default request;
