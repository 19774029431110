import React, { Component } from 'react';
import CardTablePayments from "components/Cards/CardTablePayments.js";
import xml2js from 'xml2js';
import moment from 'moment';
import { isEmpty } from 'lodash';
import Loading from '../loading.component';

import * as functions from '../../utils/functions.util.js';
import api from '../../api';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

const columns = [
  {
    name: 'Fecha',
    selector: 'fecha',
    sortable: true,
  },
  {
    name: 'Peso',
    selector: 'peso',
    sortable: true,
    right: true,
  },
  {
    name: 'Tipo Producto',
    selector: 'tipoProducto',
    sortable: true,
    right: true,
  },
  {
    name: 'Valor Día',
    selector: 'ValorDia',
    sortable: true,
    right: true,
  },
];

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      loading: false,
    };
    this.getPayments = this.getPayments.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
  }

  async componentDidMount() {
    if (localStorage.role === 'proveedor') {
      this.getPayments({
          start: moment(functions.subtractDays(new Date(), 1).setHours(0, 0, 0, 0)).locale('es').format('YYYY-MM-DD'),
          end:  moment(new Date().setHours(23, 59, 59, 999)).locale('es').format('YYYY-MM-DD')
        },
        localStorage.nit,
        localStorage.plant,
      );
    }
  }

  async getPayments(
    dateRange = {
      start: moment(functions.subtractDays(new Date(), 1).setHours(0, 0, 0, 0)).locale('es').format('YYYY-MM-DD'),
      end:  moment(new Date().setHours(23, 59, 59, 999)).locale('es').format('YYYY-MM-DD')
    },
      nit = '',
      plant = ''
  ) {
    this.setState({ loading: true });
    const payments = [];

    let res = null;

    if (localStorage.role && localStorage.role === 'proveedor') {
      res = await api.payments.getPayments(localStorage.nit, localStorage.plant, dateRange);      
    } else {
      res = await api.payments.getPayments(nit, plant, dateRange);
    }

    xml2js.parseString(res.data, {trim: true, explicitArray: true}, (err, result) => {
      if(err) {
        throw err;
      }

      if (result['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['WsProduccionSDT.HISTORICOPAGOSResponse'][0]['Crmpagos'][0]['CRMPagosItem']) {
        result['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['WsProduccionSDT.HISTORICOPAGOSResponse'][0]['Crmpagos'][0]['CRMPagosItem'].map((item) => {
          payments.push({
            fecha: item.Fecha[0],
            peso:  item.Peso[0],
            tipoProducto:  item.TipoProducto[0],
            ValorDia:  item.ValorDia[0],
          });
        })
        this.setState({ payments });
      } else {
        this.setState({ payments: [] });
      }
      this.setState({ loading: false });
    });
  }

  onChangeFilter(range, provider) {
    if (
      (provider && provider.nit && !isEmpty(provider.nit)) || 
      (provider && provider.plant && !isEmpty(provider.plant))
    ) {
      this.getPayments({
        start: moment(range.startDate).locale('es').format('YYYY-MM-DD'),
        end: moment(range.endDate).locale('es').format('YYYY-MM-DD')
      },
      provider.nit,
      provider.plant,
      );
    }

    if (localStorage.role === 'proveedor') {
      this.getPayments({
          start: moment(range.startDate).locale('es').format('YYYY-MM-DD'),
          end: moment(range.endDate).locale('es').format('YYYY-MM-DD')
        },
        localStorage.nit,
        localStorage.plant,
      );
    }
  }

  render() {
    const { payments, loading } = this.state;

    return (
      <>
        <div style={{ marginTop: '-119px', zIndex: 1001}} className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            <CardTablePayments title="Historico de pagos" onChangeFilter={this.onChangeFilter} payments={payments} loading={loading} />
            <DataTableExtensions
              columns={columns}
              data={payments}
              exportHeaders
              filter
              filterPlaceholder="Buscar"
            >
              <DataTable
                title="Historico Pagos"
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover
              />
            </DataTableExtensions>
            {
              loading ? (<center><Loading /></center>): null
            }
          </div>
        </div>
      </>
    )
  }
}

export default Users;
