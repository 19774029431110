import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import PaymentsHistory from "components/payments/paymentsHistory.component.js";
import AddUser from "components/users/addUser.component.js";
import AddVisitors from "components/visitors/addVisitors.component";
import Users from "components/users/users.component.js";
import AddMaterial from "components/materials/addMaterial.component";
import Materials from "components/materials/materials.component";
import AddProvider from "components/providers/addProvider.component";
import Providers from "components/providers/providers.component";
import KilosProvider from "components/kilos/kilos.component";
import fertilizante from "components/fertilizante/fertilizante.component";
import Activities from "components/activities/activities.component";
import visitors from "components/visitors/visitors.component";
import Vinculados from "components/users/vinculados.component"
import { isEmpty } from "lodash";


const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} 
  render={(props) => (
      localStorage.getItem('role') !== "proveedor"
          ? <Component {...props} />
          : <Redirect to='/admin/dashboard' />
      )} 
  />
}

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <PrivateRoute path="/admin/editar-usuario/:id" component={AddUser} />
            <PrivateRoute path="/admin/registrar-usuario" component={AddUser} />
            <PrivateRoute path="/admin/usuarios" component={Users} />
            {/* <Route path="/admin/editar-material/:id" component={AddMaterial} /> */}
            {/* <Route path="/admin/registrar-material" component={AddMaterial} /> */}
            {/* <Route path="/admin/materiales" component={Materials} /> */}
            {/* <Route path="/admin/editar-proveedor/:id" component={AddProvider} /> */}
            {/* <Route path="/admin/registrar-proveedor" component={AddProvider} /> */}
            <PrivateRoute path="/admin/proveedores" component={Providers} /> 
            <PrivateRoute  path="/admin/vinculados" component={Vinculados} /> 
            
            {/* <Route path="/admin/historico-pagos" component={PaymentsHistory} /> */}
            <Route path="/admin/kilos-proveedor" component={KilosProvider} />
            <Route path="/admin/fertilizante" component={fertilizante} />
            <PrivateRoute path="/admin/actividades" component={Activities} />
            <PrivateRoute path="/admin/asesores" component={visitors} />
            <PrivateRoute path="/admin/editar-asesores/:id" component={AddVisitors} />
            <PrivateRoute path="/admin/registrar-asesores" component={AddVisitors} />
            <Route path="/admin/dashboard" exact component={Dashboard} />
            {/* <Route path="/admin/maps" exact component={Maps} /> */}
            {/* <Route path="/admin/settings" exact component={Settings} /> */}
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
