import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import CardTableUsers from "components/Cards/CardTableUsers.js";
import DataTable from 'react-data-table-component';
import Loading from '../loading.component';
import { Link } from "react-router-dom";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import _ from 'lodash';

import api from '../../api';
class Vinculados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: false,
      columns: [
        {
          name: 'Correo Electrónico',
          selector: 'email',
          sortable: true,
          cell: d => <div className="mw-200 worldWrap">{d.email}</div>,
        },
        {
          name: 'identificación',
          selector: 'identificationCard',
          sortable: true,
          right: true,
        },
        {
          name: 'Nombre',
          selector: 'names',
          sortable: true,
          right: true,
        },
        {
          name: 'Apellido',
          selector: 'surnames',
          sortable: true,
          right: true,
        },
        {
          name: 'Role',
          selector: 'role',
          sortable: true,
          right: true,
        },
      ]
    };
  }


  async componentDidMount() {
    this.setState({ loading: true });
    const { data } = await api.users.getVinculadosBuyPlanta();
    this.setState({ users: data, loading: false });
  }


  render() {
    const { users, loading, columns } = this.state;
    return (
      <>
        <div style={{ marginTop: '-150px', zIndex: 1001}} className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            {/* <CardTableUsers title="Usuarios" setUsers={this.setUsers} users={users} loading={loading} /> */}
            <DataTableExtensions
              columns={columns}
              data={users}
              exportHeaders
              filter
              filterPlaceholder="Buscar"
            >
              <DataTable
                title="Usuarios"
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover
              />
            </DataTableExtensions>
            {
              loading ? (
                <center className="m-4"><Loading /></center>
              ) : null
            }
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Vinculados);
