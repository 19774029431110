import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { db } from "./firestore.config";

export const getActivities = () => {
  return new Observable((observer) =>
    db.collection("activities").onSnapshot(observer)
  ).pipe(
    map((data) => {
      return data.docs.map((d) => {
        return { _id: d.id, ...d.data() };
      });
    })
  );
};

export const getAllFormWithActivity = () => {
  return db
  .collection("form")
  .get();
};

export const getFormByActivityId = (activity) => {
  return new Observable((observer) =>
    db.collection("form").where("activity", "==", activity).onSnapshot(observer)
  ).pipe(
    map((data) => {
      return data.docs.map((d) => {
        return { _id: d.id, ...d.data() };
      });
    })
  );
};

export const getByManagerId = (id) => {
  if (localStorage.role === "gerente" && localStorage.plt) {
    return new Observable((observer) =>
      db
        .collection("activities")
        .where("plant", "==", localStorage.plt === "tumaco" ? "TCO" : "ESC")
        .onSnapshot(observer)
    ).pipe(
      map((data) => {
        return data.docs.map((d) => {
          return { _id: d.id, ...d.data() };
        });
      })
    );
  } else if (localStorage.role === "director" && localStorage.plant) {
    return new Observable((observer) =>
      db
        .collection("activities")
        .where("plant", "==", localStorage.plant)
        .where("director", "==", id)
        .onSnapshot(observer)
    ).pipe(
      map((data) => {
        return data.docs.map((d) => {
          return { _id: d.id, ...d.data() };
        });
      })
    );
  } else {
    return new Observable((observer) =>
      db
        .collection("activities")
        .where("gerente", "==", id)
        .where("plant", "==", localStorage.plant)
        .onSnapshot(observer)
    ).pipe(
      map((data) => {
        return data.docs.map((d) => {
          return { _id: d.id, ...d.data() };
        });
      })
    );
  }
};

export const getActivityById = (_id) => {
  return db.collection("activities").doc(_id).get();
};

export const save = (activity) => {
  db.collection("activities").add({
    ...activity,
    completed: false,
    created_at: new Date(),
    updated_at: new Date(),
  });
};

export const update = (activity, _id) => {
  db.collection("activities")
    .doc(_id)
    .update({
      ...activity,
      updated_at: new Date(),
    });
};

export const remove = (_id) => {
  db.collection("activities").doc(_id).delete();
};
