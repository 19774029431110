import React, { Component } from "react";
import { DateRangePicker } from 'materialui-daterange-picker';
import {Dialog, Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import moment from 'moment';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import _ from 'lodash';

import { Loading2 } from '../loading.component';
import * as functions from '../../utils/functions.util.js';
import api from '../../api';

// components
class CardTable extends Component {
  constructor(props) {
    super(props);

    const startDate = functions.subtractDays(new Date(), 1);
		startDate.setHours(0, 0, 0, 0);
		const endDate = new Date();
		endDate.setHours(23, 59, 59, 999);

    this.state = {
      providers: [],
      provider: null,
      selectedOption: null,
      providersLoading: false,
      dateRange: {
        startDate,
        endDate,
      },
      showDatePickerRange: false,
      listRanges: [
        {
          label: 'Hoy',
          startDate: new Date(),
          endDate: new Date(),
        },
        {
          label: 'Ayer',
          startDate: functions.subtractDays(new Date(), 1),
          endDate: functions.subtractDays(new Date(), 1),
        },
        {
          label: 'Esta Semana',
          startDate: functions.getFirstDayWeek(),
          endDate: functions.addDays(functions.getFirstDayWeek(), 6),
        },
        {
          label: 'Semana Pasada ',
          startDate: functions.getFirstDayLastWeek(),
          endDate: functions.addDays(functions.getFirstDayLastWeek(), 6),
        },
        {
          label: 'Últimos 7 Días',
          startDate: functions.subtractDays(new Date(), 7),
          endDate: new Date(),
        },
        {
          label: 'Este Mes',
          startDate: functions.getFirstDayMonth(),
          endDate: functions.getLastDayMonth(),
        },
        {
          label: 'Mes Pasado',
          startDate: functions.getFirstDayLastMonth(),
          endDate: functions.getLastDayLastMonth(),
        },
      ],
    };

    this.handleDateFilters = this.handleDateFilters.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.onChangeProvider = this.onChangeProvider.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ providers: this.props.providers, providersLoading: true });
    api.providers.getProviders().then((r) => {
      this.setState({ providers: r.data, providersLoading: false });
    });
  }

  handleDateFilters() {
		const { showDatePickerRange } = this.state;
		this.setState({ showDatePickerRange: !showDatePickerRange });
	}

	handleChangeDate(range) {
    const { onChangeFilter } = this.props;
    const { provider, providers } = this.state;

		range.startDate.setHours(0, 0, 0, 0);
		range.endDate.setHours(23, 59, 59);

		this.setState({ dateRange: range, showDatePickerRange: false });
    const provider2 = _.find(providers, { nit: provider });
    onChangeFilter(range, provider2);
	}

  onChangeProvider(e) {
    const { onChangeFilter } = this.props;
    const { dateRange, providers } = this.state;
    
    this.setState({ provider: e.target.value });
    
    const provider = _.find(providers, { nit: e.target.value });
    onChangeFilter(dateRange, provider);
  }
  
  handleChange(selectedOption) {
    const { dateRange, providers } = this.state;
    const { onChangeFilter } = this.props;
    this.setState({ provider: selectedOption.value, selectedOption });  
    const provider = _.find(providers, { nit: selectedOption.value });
    onChangeFilter(dateRange, provider);
  }

  render () {
    const { showDatePickerRange, dateRange, listRanges, providersLoading, providers, selectedOption } = this.state;

    return (
      <>
        <div style={{ marginTop: '-60px' }} className="flex flex-wrap mt-3">       
          <div className="w-full lg:w-4/12">
            <div className="relative w-full mb-2">
              <label
                  className="block uppercase text-white text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Fecha
                </label>
                <Button className="date-filter" onClick={this.handleDateFilters} >
                  <Icons.DateRange className="calendar-icon" />
                  <div className="dates-counters">
                    <label>Fecha</label>
                      <div>
                        <span>
                          {moment(dateRange.startDate).locale('es').format('MMMM D,\xa0\xa0YYYY\xa0\xa0')} -
                        </span>
                        <span>
                          {moment(dateRange.endDate).locale('es').format('\xa0\xa0MMMM D,\xa0\xa0YYYY\xa0\xa0')}
                        </span>
                      </div>
                  </div>
                </Button>
                <Dialog maxWidth="lg" open={showDatePickerRange} onClose={() => this.setState({ showDatePickerRange: false })}>
                  <DateRangePicker
                    open={showDatePickerRange}
                    toggle={this.handleDateFilters}
                    initialDateRange={dateRange}
                    onChange={this.handleChangeDate}
                    wrapperClassName="date-range"
                    minDate={new Date(0, 0, 0)}
                    definedRanges={listRanges}
                    maxDate={new Date()}
                  />
                </Dialog>
            </div>
          </div>
          {
            localStorage.role !== 'proveedor' ? (
              <div className="w-full lg:w-4/12">
                <div className="relative w-full mb-2">
                  <label
                    className="block uppercase text-white text-xs font-bold mb-2 flex flex-wrap items-center"
                    htmlFor="grid-password"
                  >
                    Proveedor
                    { providersLoading ? (<Loading2 />) : null }
                  </label>

                  <Select
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={providers.map((p) => {  return { value: p.id, label: p.representative_name }})}
                  />

                  {/* <select
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    onChange={this.onChangeProvider}
                  >
                    <option value="">Seleccione un proveedor</option>
                    {
                      providers.map((p, index) => {
                        return (<option key={`provider-${p.id}${index}`} value={p.nit}>{p.nit} - {p.representative_name}</option>)
                      })
                    }
                  </select> */}
                </div>
              </div>
            ) : null
          }
        </div>
       </>
    );
  }
}

function mapStateToProps(state) {
  return {
    providers: state.providers.providers,
  }
}

export default connect(mapStateToProps, {})(CardTable);

CardTable.defaultProps = {
  color: "light",
  title: "Title",
  users: [],
  setUsers: () => { }
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
  title: PropTypes.string,
  setUsers: PropTypes.func
};