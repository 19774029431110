import React, { Component } from 'react';
import { DateRangePicker } from 'materialui-daterange-picker';
import {Dialog, Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';

import Loading, { Loading2 } from '../loading.component';
import * as functions from '../../utils/functions.util.js';
import api from '../../api';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import xml2js from 'xml2js';
import { isEmpty } from 'lodash';


const columns = [
  {
    name: 'Fecha',
    selector: 'fecha',
    sortable: true,
  },
  {
    name: 'Cantidad',
    selector: 'cantidad',
    sortable: true,
    right: true,
  },
  {
    name: 'Unidad Medida ',
    selector: 'unidadMedida',
    sortable: true,
    right: true,
  },
  {
    name: 'Tipo Producto',
    selector: 'tipoProducto',
    sortable: true,
    right: true,
  },
  {
    name: 'Variedad',
    selector: 'variedad',
    sortable: true,
    right: true,
  },
];
class Kilos extends Component {
  constructor(props) {
    super(props);

    const startDate = functions.subtractDays(new Date(), 1);
		startDate.setHours(0, 0, 0, 0);
		const endDate = new Date();
		endDate.setHours(23, 59, 59, 999);

    this.state = {
      kilos: [],
      loading: false,
      providersLoading: false,
      providers: [],
      provider: null,
      dateRange: {
        startDate,
        endDate,
      },
      selectedOption: null,
      showDatePickerRange: false,
      listRanges: [
        {
          label: 'Hoy',
          startDate: new Date(),
          endDate: new Date(),
        },
        {
          label: 'Ayer',
          startDate: functions.subtractDays(new Date(), 1),
          endDate: functions.subtractDays(new Date(), 1),
        },
        {
          label: 'Esta Semana',
          startDate: functions.getFirstDayWeek(),
          endDate: functions.addDays(functions.getFirstDayWeek(), 6),
        },
        {
          label: 'Semana Pasada ',
          startDate: functions.getFirstDayLastWeek(),
          endDate: functions.addDays(functions.getFirstDayLastWeek(), 6),
        },
        {
          label: 'Últimos 7 Días',
          startDate: functions.subtractDays(new Date(), 7),
          endDate: new Date(),
        },
        {
          label: 'Este Mes',
          startDate: functions.getFirstDayMonth(),
          endDate: functions.getLastDayMonth(),
        },
        {
          label: 'Mes Pasado',
          startDate: functions.getFirstDayLastMonth(),
          endDate: functions.getLastDayLastMonth(),
        },
      ],
    };

    this.getKilos = this.getKilos.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.handleDateFilters = this.handleDateFilters.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.onChangeProvider = this.onChangeProvider.bind(this);
		this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.setState({ providers: this.props.providers, providersLoading: true });

    api.providers.getProviders().then((r) => {
      this.setState({ providers: r.data, providersLoading: false });
    });

    if (localStorage.role === 'proveedor') {
      this.getKilos({
          start: moment(functions.subtractDays(new Date(), 1).setHours(0, 0, 0, 0)).locale('es').format('YYYY-MM-DD'),
          end:  moment(new Date().setHours(23, 59, 59, 999)).locale('es').format('YYYY-MM-DD')
        },
        localStorage.nit,
        localStorage.plant,
      );
    }
  }

  handleDateFilters() {
		const { showDatePickerRange } = this.state;
		this.setState({ showDatePickerRange: !showDatePickerRange });
	}

	handleChangeDate(range) {
    const { provider, providers } = this.state;
		range.startDate.setHours(0, 0, 0, 0);
		range.endDate.setHours(23, 59, 59);

		this.setState({ dateRange: range, showDatePickerRange: false });
    this.setState({ dateRange: range, showDatePickerRange: false });
    const provider2 = _.find(providers, { nit: provider });
    this.onChangeFilter(range, provider2);
	}

  onChangeProvider(e) {
    const { dateRange, providers } = this.state;
    
    this.setState({ provider: e.target.value });
    
    const provider = _.find(providers, { nit: e.target.value });
    this.onChangeFilter(dateRange, provider);
  }

  async getKilos(
    dateRange = {
      start: moment(functions.subtractDays(new Date(), 1).setHours(0, 0, 0, 0)).locale('es').format('YYYY-MM-DD'),
      end:  moment(new Date().setHours(23, 59, 59, 999)).locale('es').format('YYYY-MM-DD')
    },
      nit = '',
      plant = ''
  ) {
    this.setState({ loading: true });
    const kilos = [];

    let res = null;

    if (localStorage.role && localStorage.role === 'proveedor') {
      res = await api.payments.getKilos(localStorage.nit, localStorage.plant, dateRange);      
    } else {
      res = await api.payments.getKilos(nit, plant, dateRange);
    }

     xml2js.parseString(res.data, {trim: true, explicitArray: true}, (err, result) => {
      if(err) {
        throw err;
      }

      if (result['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['WsProduccionSDT.CANTIDADKILOSPROVEEDORResponse'][0]['Crmpeso'][0]['CRMPesoItem']) {
        result['SOAP-ENV:Envelope']['SOAP-ENV:Body'][0]['WsProduccionSDT.CANTIDADKILOSPROVEEDORResponse'][0]['Crmpeso'][0]['CRMPesoItem'].map((item) => {
            kilos.push({
              fecha: item.Fecha[0],
              cantidad:  item.Cantidad[0],
              unidadMedida:  item.UnidadMedida[0],
              tipoProducto:  item.TipoProducto[0],
              variedad: item.Variedad[0]
            });
          })
          this.setState({ kilos });
      } else {
        this.setState({ kilos: [] });
      }

      this.setState({ loading: false });
    });
  }

  onChangeFilter(range, provider) {
    if (
      (provider && provider.nit && !isEmpty(provider.nit)) || 
      (provider && provider.plant && !isEmpty(provider.plant))
    ) {
      this.getKilos({
        start: moment(range.startDate).locale('es').format('YYYY-MM-DD'),
        end: moment(range.endDate).locale('es').format('YYYY-MM-DD')
      },
      provider.nit,
      provider.plant,
      );
    }

    if (localStorage.role === 'proveedor') {
      this.getKilos({
          start: moment(range.startDate).locale('es').format('YYYY-MM-DD'),
          end: moment(range.endDate).locale('es').format('YYYY-MM-DD')
        },
        localStorage.nit,
        localStorage.plant,
      );
    }
  }

  handleChange(selectedOption) {
    const { dateRange, providers } = this.state;
    this.setState({ provider: selectedOption.value, selectedOption });  
    const provider = _.find(providers, { nit: selectedOption.value });
    this.onChangeFilter(dateRange, provider);
  }

  render() {
    const { kilos, showDatePickerRange, dateRange, listRanges, loading, providersLoading, providers, selectedOption } = this.state;
  
    return (
      <>
        <div style={{ marginTop: '-119px', zIndex: 1001}} className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            <div style={{ marginTop: '-60px' }} className="flex flex-wrap mt-3">
              
              <div className="w-full lg:w-4/12">
                  <div className="relative w-full mb-3">
                    <label
                        className="block uppercase text-white text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Fecha
                      </label>
                      <Button className="date-filter" onClick={this.handleDateFilters} >
                        <Icons.DateRange className="calendar-icon" />
                        <div className="dates-counters">
                          <label>Fecha</label>
                            <div>
                              <span>
                                {moment(dateRange.startDate).locale('es').format('MMMM D,\xa0\xa0YYYY\xa0\xa0')} -
                              </span>
                              <span>
                                {moment(dateRange.endDate).locale('es').format('\xa0\xa0MMMM D,\xa0\xa0YYYY\xa0\xa0')}
                              </span>
                            </div>
                        </div>
                      </Button>
                      <Dialog maxWidth="lg" open={showDatePickerRange} onClose={() => this.setState({ showDatePickerRange: false })}>
                        <DateRangePicker
                          open={showDatePickerRange}
                          toggle={this.handleDateFilters}
                          initialDateRange={dateRange}
                          onChange={this.handleChangeDate}
                          wrapperClassName="date-range"
                          minDate={new Date(0, 0, 0)}
                          definedRanges={listRanges}
                          maxDate={new Date()}
                        />
                      </Dialog>
                  </div>
                </div>
              {/* <CardTableKilos title="Kilos Proveedores" onChangeFilter={this.onChangeFilter} kilos={kilos} loading={loading} /> */}
              {
                localStorage.role !== 'proveedor' ? (
                  <div className="w-full lg:w-4/12">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-white text-xs font-bold mb-2 flex flex-wrap items-center"
                        htmlFor="grid-password"
                      >
                        Proveedor
                        { providersLoading ? (<Loading2 />) : null }
                      </label>

                      <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={providers.map((p) => {  return { value: p.id, label: p.representative_name }})}
                      />

                      {/* <select
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        onChange={this.onChangeProvider}
                      >
                        <option value="">Seleccione un proveedor</option>
                        {
                          providers.map((p, index) => {
                            return (<option key={`provider-${p.id}${index}`} value={p.nit}>{p.nit} - {p.representative_name}</option>)
                          })
                        }
                      </select> */}
                    </div>
                  </div>
                ) : null
              }
            </div>
            <DataTableExtensions
              columns={columns}
              data={kilos}
              exportHeaders
              filter
              filterPlaceholder="Buscar"
            >
              <DataTable
                title="Proveedores"
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover
              />
            </DataTableExtensions>
            {loading ? <Loading /> : null }
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    providers: state.providers.providers,
  }
}

export default connect(mapStateToProps, {})(Kilos)