/* eslint-disable no-restricted-globals */


export const subtractDays = (date, days) => {
	return new Date(new Date(date).setDate(date.getDate() - days));
};

export const addDays = (date, days) => {
	return new Date(new Date(date).setDate(date.getDate() + days));
};

export const getFirstDayWeek = () => {
	const curr = new Date();
	const first = curr.getDate() - curr.getDay();
	return new Date(curr.setDate(first));
};

export const getFirstDayLastWeek = () => {
	return subtractDays(getFirstDayWeek(), 7);
};

export const getFirstDayMonth = () => {
	const date = new Date();
	return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getLastDayMonth = () => {
	const date = new Date();
	return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getLastDayLastMonth = () => {
	const date = new Date();
	date.setMonth(date.getMonth() - 1);
	return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getFirstDayLastMonth = () => {
	const date = new Date();
	date.setMonth(date.getMonth() - 1);
	return new Date(date.getFullYear(), date.getMonth(), 1);
};


export const subtractYears = (date, years) => {
	return new Date(new Date(date).setFullYear(date.getFullYear() - years));
};