import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import CardTableUsers from "components/Cards/CardTableUsers.js";
import DataTable from 'react-data-table-component';
import Loading from '../loading.component';
import { Link } from "react-router-dom";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import _ from 'lodash';

import api from '../../api';
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: false,
      columns: [
        {
          name: 'Correo Electrónico',
          selector: 'email',
          sortable: true,
          cell: d => <div className="mw-200 worldWrap">{d.email}</div>,
        },
        {
          name: 'identificación',
          selector: 'identificationCard',
          sortable: true,
          right: true,
        },
        {
          name: 'Nombre',
          selector: 'names',
          sortable: true,
          right: true,
        },
        {
          name: 'Apellido',
          selector: 'surnames',
          sortable: true,
          right: true,
        },
        {
          name: 'Role',
          selector: 'role',
          sortable: true,
          right: true,
        },
        {
          name: 'Estado',
          selector: 'state',
          sortable: true,
          right: true,
          cell: d => <div className="mw-200 worldWrap">{d.state == 1 ? 'Activo' : 'Inactivo'}</div>,

        },
        {
          name: 'Editar',
          selector: 'state',
          sortable: true,
          center: true,
          sortable: true,
          cell: row => (<div className="flex align-items justify-center">
            <button className="bg-white flex align-items justify-center shadow-lg m-1 text-black font-bold rounded-2 p-2 " onClick={() => this.edit(row.id)}>
              <i className="text-center fas fa-edit text-sm text-black"></i>
            </button>
            </div>)
            
        },
       
        {
          name: 'Activar/Inactivar',
          sortable: true,
          center: true,
          cell: row => (<div className="flex align-items justify-center">
            <button onClick={() => this.changeStatus(row.id)}  className="p-2  m-1 bg-white flex align-items justify-center shadow-lg  text-black font-bold rounded-2">
              <i class="fas fa-exchange-alt text-sm text-black text-center"></i>
            </button>    
          </div>)
        },
      ]
    };
    this.setUsers = this.setUsers.bind(this);
    this.edit = this.edit.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }


  async componentDidMount() {
    this.setState({ loading: true });
    const { data } = await api.users.getUsers();
    const users = _.filter(data, (user) => {
      return user.role !== 'proveedor';
    });
    this.setState({ users, loading: false });

  }

  setUsers(users = null) {
    if (users !== null) {
      this.setState({ users });
    }
  }

  edit = (id=null) => {
    const { history } = this.props;

    if (id !== null) {
      history.push(`/admin/editar-usuario/${id}`);
    }
  }

  changeStatus = (id=null) => {
    const { users } = this.state;

    if (id !== null) {
      
      const index = _.findIndex(users, { id });
      const status = !users[index].state;
      api.users.changeStatus(id, status);
      users[index].state = status;
      this.setState({ users });
      window.location.reload();
    }
  }

  render() {
    const { users, loading, columns } = this.state;
    return (
      <>
        <div style={{ marginTop: '-150px', zIndex: 1001}} className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            <Link
              className="position-absolute my-5-reverse bg-white text-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              to="/admin/registrar-usuario"
            >
              Crear Usuario
            </Link>
            {/* <CardTableUsers title="Usuarios" setUsers={this.setUsers} users={users} loading={loading} /> */}
            <DataTableExtensions
              columns={columns}
              data={users}
              exportHeaders
              filter
              filterPlaceholder="Buscar"
            >
              <DataTable
                title="Usuarios"
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover
              />
            </DataTableExtensions>
            {
              users.length === 0 && loading === false ? (
                <center><b><p className="m-4">No se encontraron registros</p></b></center>
              ) : null
            }
            {
              loading ? (
                <center className="m-4"><Loading /></center>
              ) : null
            }
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Users);
