import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});
const returValuepoda = (Calificacion) => {
  let value = "";
  switch (Calificacion) {
    case "1":
        value = "Defiente"
      break;
      case "2":
        value = "Aceptable"
      break;
      case "3":
        value = "Por Mejorar"
      break;
      case "4":
        value = "Optimo"
      break;
      case "5":
        value = "Excelente"
      break;
  }
  return value;
}
export default function ActivityForm({ form }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {
        form !== null ? (
          <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox checked={(form.mejora !== '' ||
                form.plateos !== '' ||
                form.podas !== '' ||
                form.precensia_gramineas !== '' ||
                form.competencia_arvenses !== '' ||
                form.nectariferas !== '' ||
                form.recomendaciones !== '')} />}
                label="Labores de mantenimiento"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                <p>Mejora:  {form.mejora }</p>
                <p>Plateos: {returValuepoda(form.plateos) }</p>
                <p>Podas: {returValuepoda(form.podas) }</p>
                <p>Precensia gramíneas: {form.precensia_gramineas}</p>
                <p>Competencia arvenses (maleza): {form.competencia_arvenses}</p>
                <p>Nectaríferas: {form.nectariferas}</p>
                <p>Recomendaciones: {form.recomendaciones}</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions2-content"
              id="additional-actions2-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox checked={(form.se_fertiliza !== '' ||
                form.kg_aplicados_actual !== '' ||
                form.aplica_fertilizante_quimico !== '' ||
                form.kg_aplicados_anterior !== '' ||
                form.boro !== '' ||
                form.dap !== '' ||
                form.kcl !== '' ||
                form.kieserita !== '' ||
                form.palmero !== '' ||
                form.otros_nombre !== '' ||
                form.otros_valor !== '' ||
                form.otros_nombre_2 !== '' ||
                form.otros_valor_2 !== '' ||
                form.no_aplicaciones !== '' ||
                form.aplica_enmienda !== '' ||
                form.kg_enmienda !== '' ||
                form.recomendaciones2 !== '')} />}
                label="Fertilización"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                <p><b>Aplica o incorpora materia orgánica: </b> {form.se_fertiliza} </p>
                <p><b>Kg materia orgánica aplicados / palma año actual: </b> {form.kg_aplicados_actual} </p>
                <p><b>Aplica Fertilizante químico : </b> {form.aplica_fertilizante_quimico} </p>
                <p><b>Kg aplicados/palma  año anterior: </b> {form.kg_aplicados_anterior} </p>
                <p><b>Boro (kg): </b> {form.boro} </p>
                <p><b>DAP (kg): </b> {form.dap} </p>
                <p><b>KCL (kg): </b> {form.kcl} </p>
                <p><b>Kieserita (kg): </b> {form.kieserita} </p>
                <p><b>Palmero (kg): </b> {form.palmero} </p>
                <p><b>Otros nombre/valor (kg): </b> {form.otros_nombre}/{form.otros_valor} </p>
                <p><b>Otros nombre/valor (kg): </b> {form.otros_nombre_2}/{form.otros_valor_2} </p>
                <p><b>Aplicaciones entradas anuales: </b> {form.no_aplicaciones} </p>
                <p><b>Aplica enmienda: </b> {form.aplica_enmienda} </p>
                <p><b>Kg de enmienda palma/ año: </b> {form.kg_enmienda} </p>
                <p><b>Recomendaciones: </b> {form.recomendaciones2} </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions3-content"
              id="additional-actions3-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox checked={(
                form.frecuencia_censo_sanitario !== '' ||
                form.pudricion_de_cogollo !== '' ||
                form.pudricion_de_cogollo_tiempo_censo !== '' ||
                form.pudricion_de_cogollo_incidencia !== '' ||
                form.anillo_rojo !== '' ||
                form.anillo_rojo_tiempo_censo !== '' ||
                form.anillo_rojo_incidencia !== '' ||
                form.marchiez_letal !== '' ||
                form.marchiez_letal_tiempo_censo !== '' ||
                form.marchiez_letal_incidencia !== '' ||
                form.sagalassa !== '' ||
                form.sagalassa_tiempo_censo !== '' ||
                form.sagalassa_incidencia !== '' ||
                form.opsiphanes !== '' ||
                form.opsiphanes_tiempo_censo !== '' ||
                form.opsiphanes_incidencia !== '' ||
                form.otras !== '' ||
                form.recomendaciones3 !== '')} />}
                label="Sanidad"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                <p><b>Frecuencia de censo sanitario: </b> {form.frecuencia_censo_sanitario}</p>
                <p><b>Pudrición de cogollo: </b> {form.pudricion_de_cogollo}</p>
                <p><b>Pudrición de cogollo - Tiempo de censo: </b> {form.pudricion_de_cogollo_tiempo_censo}</p>
                <p><b>Pudrición de cogollo - Incidencia: </b> {form.pudricion_de_cogollo_incidencia}</p>
                <p><b>Anillo rojo: </b> {form.anillo_rojo}</p>
                <p><b>Anillo rojo - Tiempo de censo: </b> {form.anillo_rojo_tiempo_censo}</p>
                <p><b>Anillo rojo - Incidencia: </b> {form.anillo_rojo_incidencia}</p>
                <p><b>Sagalassa: </b> {form.sagalassa}</p>
                <p><b>Sagalassa - Tiempo de censo: </b> {form.sagalassa_tiempo_censo}</p>
                <p><b>Sagalassa - Incidencia: </b> {form.sagalassa_incidencia}</p>
                <p><b>Opsiphanes: </b> {form.opsiphanes}</p>
                <p><b>Opsiphanes - Tiempo de censo: </b> {form.opsiphanes_tiempo_censo}</p>
                <p><b>Opsiphanes - Incidencia: </b> {form.opsiphanes_incidencia}</p>
                <p><b>Marchides Letal: </b> {form.marchiez_letal}</p>
                <p><b>Marchides Letal - Tiempo de censo: </b> {form.marchiez_letal_tiempo_censo}</p>
                <p><b>Marchides Letal - Incidencia: </b> {form.marchiez_letal_incidencia}</p>
                <p><b>Otras: </b> {form.otras}</p>
                <p><b>Recomendaciones: </b> {form.recomendaciones3}</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions3-content"
              id="additional-actions3-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox checked={(
                form.no_racimos_muestra !== '' ||
                form.racimos_verdes !== '' ||
                form.racimos_pasados !== '' ||
                form.pedunculo_largo !== '' ||
                form.racimos_enfermo !== '' ||
                form.racimos_sobremaduros !== '' ||
                form.racimos_mal_polonizados !== '' ||
                form.ciclos !== '' ||
                form.recoleccion_de_fruto_suelto !== '' ||
                form.recomendaciones4 !== '')} />}
                label="Cosecha, valoración fruto cortado al momento de la visita"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                <p><b>Hay fruto cortado:</b> {form.fruto_cortado}</p>
                <p><b>Número racimos de la muestra:</b> {form.no_racimos_muestra}</p>
                <p><b>% Racimos verdes:</b> {form.racimos_verdes}</p>
                <p><b>% Racimos pasados:</b> {form.racimos_pasados}</p>
                <p><b>% Pedúnculo largo:</b> {form.pedunculo_largo}</p>
                <p><b>% Racimos sobremaduros:</b> {form.racimos_sobremaduros}</p>
                <p><b>% Racimos enfermo:</b> {form.racimos_enfermo}</p>
                <p><b>% Racimos mal polinizados:</b> {form.racimos_mal_polonizados}</p>
                <p><b>Días ciclos cosecha:</b> {form.ciclos}</p>
                <p><b>Recolección de fruto suelto:</b> {form.recoleccion_de_fruto_suelto}</p>
                <p><b>Recomendaciones:</b> {form.recomendaciones4}</p>  
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions3-content"
              id="additional-actions3-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox checked={(form.registros_labores !== '' ||
                form.evidencia_tala_de_bosques !== '' ||
                form.evidencia_toma_de_agua !== '' ||
                form.evidencia_practicas_quemas !== '' ||
                form.no_trabajadores_permanentes !== '' ||
                form.no_trabajadores_destajo !== '' ||
                form.recomendaciones5 !== '')} />}
                label="Sostenibilidad"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                <p><b>Registros labores que hace en campo:</b> {form.registros_labores}</p>
                <p><b>Se evidencia tala de bosques:</b> {form.evidencia_tala_de_bosques}</p>
                <p><b>Se evidencia toma de agua:</b> {form.evidencia_toma_de_agua}</p>
                <p><b>Se evidencia prácticas de quemas:</b> {form.evidencia_practicas_quemas}</p>
                <p><b>Número trabajadores Directo:</b> {form.no_trabajadores_permanentes}</p>
                <p><b>Número trabajadores al diario:</b> {form.no_trabajadores_destajo}</p>
                <p><b>Recomendaciones:</b> {form.recomendaciones5}</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions3-content"
              id="additional-actions3-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox checked={(form.hectaresa_polen !== '' ||
                form.no_entradas !== '' ||
                form.dosis !== '' ||
                form.ana !== '' ||
                form.no_entradas_ana !== '' ||
                form.ciclos_revision_inflorescencias !== '' ||
                form.recomendaciones6 !== '')} />}
                label="Aplicación regulador y/o polen"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                <p>Hectáresa polen: {form.hectaresa_polen}</p>
                <p>No. Entradas: {form.no_entradas}</p>
                <p>Dosis / entrada (gramos): {form.dosis}</p>
                <p>Hectáresa ANA: {form.ana}</p>
                <p>No entradas aplicación ANA: {form.ana}</p>
                <p>Ciclos (días) revisión inflorescencias: {form.ciclos_revision_inflorescencias}</p>
                <p>Recomendaciones: {form.recomendaciones6}</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </>
        ) : null
      }
      </div>
  );
}
