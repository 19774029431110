import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '50px',
    marginBottom: '50px'
  },
  root2: {
    '&> div': {
      display: 'flex',
      width: '20px !important',
      height: '20px !important',
      color: 'white !important',
      marginLeft: 4
    }
  },
  root3: {
    '&> div': {
      display: 'flex',
      width: '20px !important',
      height: '20px !important',
      marginLeft: 4
    }
  }
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}

export function Loading2() {
  const classes = useStyles();
  return (
    <div className={classes.root2}>
      <CircularProgress />
    </div>
  );
}

export function Loading3() {
  const classes = useStyles();
  return (
    <div className={classes.root3}>
      <CircularProgress />
    </div>
  );
}