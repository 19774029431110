import React, { Component } from "react";
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/es';
import Select from 'react-select';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Loading2 } from '../loading.component';
import PropTypes from "prop-types";
import api from '../../api';
class CardTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providers: [],
      date: new Date(),
      providersLoading: false,
      selectedOption: false,
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
		this.onChangeProvider = this.onChangeProvider.bind(this);
		this.handleChange = this.handleChange.bind(this);
  }


  componentDidMount() {
    this.setState({ providers: this.props.providers, providersLoading: true });
    api.providers.getProviders().then((r) => {
      this.setState({ providers: r.data, providersLoading: false });
    });
  }

	handleChangeDate(date) {
    const { onChangeFilter } = this.props;
    const { provider, providers } = this.state;
		this.setState({ date });
    const provider2 = _.find(providers, { nit: provider });
    onChangeFilter(date, provider2);
	}

  onChangeProvider(e) {
    const { onChangeFilter } = this.props;
    const { date, providers } = this.state;
    
    this.setState({ provider: e.target.value });
    
    const provider = _.find(providers, { nit: e.target.value });
    onChangeFilter(date, provider);
  }


  handleChange(selectedOption) {
    const { date, providers } = this.state;
    const { onChangeFilter } = this.props;

    this.setState({ provider: selectedOption.value, selectedOption });  
    const provider = _.find(providers, { nit: selectedOption.value });
    onChangeFilter(date, provider);
  }

  render (){
    const { providers, date, providersLoading, selectedOption } = this.state;
    return (
      <>
        <div style={{ marginTop: '-60px' }} className="flex flex-wrap mt-3">
            <div className="w-full lg:w-4/12">
              <div className="relative w-full mb-3">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                  <label
                    className="block uppercase text-white text-xs font-bold mb-2"
                    htmlFor="date-picker-dialog"
                  >
                    Fecha
                  </label>
                  <KeyboardDatePicker
                    id="date-picker-dialog"
                    format="yyyy-MM-dd"
                    error={false}
                    helperText=""
                    inputVariant="outlined"
                    variant="inline"
                    hiddenLabel
                    value={date}
                    onChange={this.handleChangeDate}
                    className="date-filter"
                  />
                </MuiPickersUtilsProvider>
                
                  {/* <label htmlFor="date-picker-dialog">
                    <Button className="date-filter">
                      <Icons.DateRange className="calendar-icon" />
                      <div className="dates-counters">
                        <label>Fecha</label>
                          <div>
                            <span>
                              {moment(date).locale('es').format('MMMM D,\xa0\xa0YYYY\xa0\xa0')}
                            </span>
                          </div>
                      </div>
                    </Button>
                  </label> */}
                  
              </div>
            </div>

            {
                localStorage.role !== 'proveedor' ? (
                  <div className="w-full lg:w-4/12">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-white text-xs font-bold mb-2 flex flex-wrap items-center"
                        htmlFor="grid-password"
                      >
                        Proveedor
                        { providersLoading ? (<Loading2 />) : null }
                      </label>

                      <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={providers.map((p) => {  return { value: p.id, label: p.representative_name }})}
                      />

                      {/* <select
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        onChange={this.onChangeProvider}
                      >
                        <option value="">Seleccione un proveedor</option>
                        {
                          providers.map((p, index) => {
                            return (<option key={`provider-${p.id}${index}`} value={p.nit}>{p.nit} - {p.representative_name}</option>)
                          })
                        }
                      </select> */}
                    </div>
                  </div>
                ) : null
              }
          </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    providers: state.providers.providers,
  }
}


export default connect(mapStateToProps, { })(CardTable);

CardTable.defaultProps = {
  color: "light",
  title: "Title",
  users: [],
  loading: false,

};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
  title: PropTypes.string,
  loading: PropTypes.bool

};
