/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import actions from "../../actions";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";

function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>

          {localStorage.role === "gerente" && localStorage.plt === "llanos" ? (
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none"
              style={{
                width: 100,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={require("assets/img/logo.png")}
            />
          ) : null}

          {localStorage.role === "gerente" && localStorage.plt === "tumaco" ? (
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none"
              style={{
                width: 150,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={require("assets/img/logo-palmas.png")}
            />
          ) : null}

          {(localStorage.role === "director" && localStorage.plant === "TCO") ||
          (localStorage.role === "proveedor" &&
            localStorage.plant === "TCO") ? (
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none"
              style={{
                width: 150,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={require("assets/img/logo-palmas.png")}
            />
          ) : null}

          {(localStorage.role === "director" && localStorage.plant === "ESC") ||
          (localStorage.role === "proveedor" &&
            localStorage.plant === "ESC") ? (
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none"
              style={{
                width: 100,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={require("assets/img/logo.png")}
            />
          ) : null}

          {localStorage.role === "admin" ? (
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none"
              style={{
                width: 200,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={require("assets/img/admin-brand.png")}
            />
          ) : null}
          <br />
          <center>
            <p>
              <b>
                {localStorage.names} {localStorage.surnames}
              </b>
            </p>
          </center>
          <center>
            <p className="uppercase text-gray-600 text-xs">
              <b>{localStorage.role}</b>
            </p>
          </center>
          {localStorage.role === "gerente" ? (
            <div>
              <br />
              <select
                value={localStorage.plt}
                onChange={(e) => {
                  localStorage.setItem("plt", e.target.value);
                  location.reload();
                }}
              >
                <option value="tumaco">Tumaco</option>
                <option value="llanos">Casanare</option>
              </select>
            </div>
          ) : null}

          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            <hr className="my-4 md:min-w-full" />
            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Administrador
            </h6>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>

              {localStorage.role === "admin" ||
              localStorage.role === "gerente" ? (
                <li className="items-center">
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/usuarios") !== -1
                        ? "text-blue-500 hover:text-blue-600"
                        : "text-gray-800 hover:text-gray-600")
                    }
                    to="/admin/usuarios"
                  >
                    <i
                      className={
                        "fas fa-user mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/usuarios") !== -1
                          ? "opacity-75"
                          : "text-gray-400")
                      }
                    ></i>
                    Usuarios
                  </Link>
                </li>
              ) : null}

              {localStorage.role === "admin" ||
              localStorage.role === "gerente" ||
              localStorage.role === "director" ? (
                <li className="items-center">
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/asesores") !== -1
                        ? "text-blue-500 hover:text-blue-600"
                        : "text-gray-800 hover:text-gray-600")
                    }
                    to="/admin/asesores"
                  >
                    <i
                      className={
                        "fas fa-street-view mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/asesores") !== -1
                          ? "opacity-75"
                          : "text-gray-400")
                      }
                    ></i>
                    Visitadores
                  </Link>
                </li>
              ) : null}

              <li className="items-center">
                {/* {localStorage.role === "admin" ? (
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/materiales") !== -1
                        ? "text-blue-500 hover:text-blue-600"
                        : "text-gray-800 hover:text-gray-600")
                    }
                    to="/admin/materiales"
                  >
                    <i
                      className={
                        "fas fa-tractor mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/materiales") !==
                        -1
                          ? "opacity-75"
                          : "text-gray-400")
                      }
                    ></i>{" "}
                    Materiales
                  </Link>
                ) : null} */}

                {localStorage.role === "admin" ||
                localStorage.role === "gerente" ||
                localStorage.role === "director" ? (
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/proveedores") !== -1
                        ? "text-blue-500 hover:text-blue-600"
                        : "text-gray-800 hover:text-gray-600")
                    }
                    to="/admin/proveedores"
                  >
                    <i
                      className={
                        "fas fa-user-cog mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/proveedores") !==
                        -1
                          ? "opacity-75"
                          : "text-gray-400")
                      }
                    ></i>{" "}
                    Provedores
                  </Link>
                ) : null}

                { localStorage.role === "gerente" ||
                localStorage.role === "director" ? (
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/vinculados") !== -1
                        ? "text-blue-500 hover:text-blue-600"
                        : "text-gray-800 hover:text-gray-600")
                    }
                    to="/admin/vinculados"
                  >
                    <i
                      className={
                        "fas fa-book-reader mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/vinculados") !==
                        -1
                          ? "opacity-75"
                          : "text-gray-400")
                      }
                    ></i>{" "}
                     Vinculados
                  </Link>
                ) : null}

                {localStorage.role === "gerente" ||
                localStorage.role === "proveedor" ||
                localStorage.role === "director" ? (
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/fertilizante") !==
                      -1
                        ? "text-blue-500 hover:text-blue-600"
                        : "text-gray-800 hover:text-gray-600")
                    }
                    to="/admin/fertilizante"
                  >
                    <i
                      className={
                        "fas fa-seedling mr-2 text-sm " +
                        (window.location.href.indexOf("/admin/fertilizante") !==
                        -1
                          ? "opacity-75"
                          : "text-gray-400")
                      }
                    ></i>{" "}
                    Fertilizante
                  </Link>
                ) : null}

                {/* {localStorage.role === "gerente" ||
                localStorage.role === "proveedor" ||
                localStorage.role === "director" ? (
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf(
                        "/admin/historico-pagos"
                      ) !== -1
                        ? "text-blue-500 hover:text-blue-600"
                        : "text-gray-800 hover:text-gray-600")
                    }
                    to="/admin/historico-pagos"
                  >
                    <i
                      className={
                        "fas fa-cash-register mr-2 text-sm " +
                        (window.location.href.indexOf(
                          "/admin/historico-pagos"
                        ) !== -1
                          ? "opacity-75"
                          : "text-gray-400")
                      }
                    ></i>{" "}
                    Historico Pagos
                  </Link>
                ) : null} */}

                {localStorage.role === "gerente" ||
                localStorage.role === "proveedor" ||
                localStorage.role === "director" ? (
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf(
                        "/admin/kilos-proveedor"
                      ) !== -1
                        ? "text-blue-500 hover:text-blue-600"
                        : "text-gray-800 hover:text-gray-600")
                    }
                    to="/admin/kilos-proveedor"
                  >
                    <i
                      className={
                        "fas fa-weight mr-2 text-sm " +
                        (window.location.href.indexOf(
                          "/admin/kilos-proveedor"
                        ) !== -1
                          ? "opacity-75"
                          : "text-gray-400")
                      }
                    ></i>{" "}
                    Kilos Provedor
                  </Link>
                ) : null}

                {localStorage.role === "proveedor" ? (
                  <a
                    href={`${localStorage.plt === 'tumaco' ? 'mailto:dkdiaz@esc.com.co;caarias@palmares.com.co' : 'mailto:lyquinones@palmaco.com.co;caarias@palmares.com.co'}`}
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf(
                        "/admin/proveodor/contactar"
                      ) !== -1
                        ? "text-blue-500 hover:text-blue-600"
                        : "text-gray-800 hover:text-gray-600")
                    }
                  >
                    <i
                      className={
                        "fas fa-envelope mr-2 text-sm " +
                        (window.location.href.indexOf(
                          "/admin/provedor/contactar"
                        ) !== -1
                          ? "opacity-75"
                          : "text-gray-400")
                      }
                    ></i>{" "}
                    Contactar
                  </a>
                ) : null}

                {localStorage.role === "gerente" ||
                localStorage.role === "director" ? (
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/admin/actividades") !== -1
                        ? "text-blue-500 hover:text-blue-600"
                        : "text-gray-800 hover:text-gray-600")
                    }
                    to="/admin/actividades"
                  >
                    <i
                      className={
                        "fas fa-calendar-check text-sm mr-2 " +
                        (window.location.href.indexOf("/admin/actividades") !==
                        -1
                          ? "opacity-75"
                          : "text-gray-400")
                      }
                    ></i>{" "}
                    Actividades
                  </Link>
                ) : null}

                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/login") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  onClick={() => {
                    localStorage.clear();
                  }}
                  to="/login"
                >
                  <i
                    className={
                      "fas fa-sign-out-alt mr-2 text-sm " +
                      (window.location.href.indexOf("/login") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>
                  Cerrar Sesión
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

// function mapStateToProps(state) {
// 	return {
// 		plant: state.plant,
// 	};
// }

// const { setPlant } = actions.plant;

// const mapDispatchToProps = {
//   setPlant,
// };

export default Sidebar;
