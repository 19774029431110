import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from '../actions';
import api from '../api';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { history } = this.props;

    if (localStorage.plt === undefined) {
      this.props.setPlant('tumaco');
    } else {
      this.props.setPlant(localStorage.plt);
    }

    api.providers.getProviders().then((r) =>{ 
      this.props.loadProviders(r.data);
    })

    if (localStorage.token) {
      // history.push('/admin');
    } else {
      history.push('/login');
    }
    
  }


  render() {
    const { children } = this.props;

    return (<>
      {children}
    </>)
  }
}

const { setPlant } = actions.plant;
const { loadProviders } = actions.providers;

const mapDispatchToProps = {
  setPlant,
  loadProviders
};

export default withRouter(connect(null, mapDispatchToProps)(App));