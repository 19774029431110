/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { createPopper } from "@popperjs/core";
import PropTypes from "prop-types";

const NotificationDropdown = ({ id, edit, remove, status, changeStatus }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="text-gray-600 py-1 px-3"
        href="#"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >

        {
          edit ? (
            <a
              href="#pablo"
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
              }
              onClick={(e) => { e.preventDefault(); edit(id); }}
            >
              Editar
            </a>
          ) : null
        }
        
        {
          remove ? (
            <a
              href="#pablo"
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
              }
              onClick={(e) => { e.preventDefault(); remove(id); }}
            >
              Eliminar
            </a>
          ) : null
        }
        
        {
           changeStatus ? (
            <a
              href="#pablo"
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
              }
              onClick={(e) => { e.preventDefault(); changeStatus(id); }}
            >
              {status ? 'Inactivar' : 'Activar'}
            </a>
           ) : null
        }
      </div>
    </>
  );
};

export default NotificationDropdown;

NotificationDropdown.defaultProps = {
  id: 0,
  edit: undefined,
  remove: undefined,
  changeStatus: undefined
};

NotificationDropdown.propTypes = {
  id: PropTypes.number,
  edit: PropTypes.func|PropTypes.undefined,
  remove: PropTypes.func|PropTypes.undefined,
  changeStatus: PropTypes.func|PropTypes.undefined
};
